import { UserAgentApplication } from 'msal';

/**
 * Configuration definition for MSAL
 */
const config = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID,
    authority: `${process.env.REACT_APP_AD_AUTHORITY_URI}/${process.env.REACT_APP_AD_TENANT_ID}`,
    validateAuthority: true,
    postLogoutRedirectUri: process.env.REACT_APP_AD_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
    redirectUri: process.env.REACT_APP_AD_REDIRECT_URL,
  },
  cache: {
    cacheLocation: 'sessionStorage'
    // TODO Implement later
    // storeAuthStateInCookie: isIE()
  },
  system: {
    navigateFrameWait: 0,
    logger: {
      error: console.error,
      errorPii: console.error,
      info: console.debug,
      infoPii: console.debug,
      verbose: console.debug,
      verbosePii: console.debug,
      warning: console.warn,
      warningPii: console.warn,
    },
  },
};

const msalApp = new UserAgentApplication(config);

/**
 * Microsoft graph scope definitions
 */
export const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  MAIL_READ: 'Mail.Read',
};

/**
 * Microsoft graph endpoints
 */
export const GRAPH_ENDPOINTS = {
  ME: 'https://graph.microsoft.com/v1.0/me',
  PROFILE_PICTURE: 'https://graph.microsoft.com/v1.0/me/photo',
  MAIL: 'https://graph.microsoft.com/v1.0/me/messages',
};

/**
 * Graph request definitions
 */
export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
  },
  EMAIL: {
    scopes: [GRAPH_SCOPES.MAIL_READ],
  },
  USER_PROFILE: {
    scopes: [GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
  },
};

export const requiresInteraction = (errorMessage) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

export const fetchMsGraph = async (url, accessToken) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.json();
};

export const acquireToken = async (request, redirect) => {
  return msalApp.acquireTokenSilent(request).catch((error) => {
    // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
    // due to consent or interaction required ONLY
    if (requiresInteraction(error.errorCode)) {
      return redirect
        ? msalApp.acquireTokenRedirect(request)
        : msalApp.acquireTokenPopup(request);
    }
  });
};

export const getAccount = () => {
  return msalApp.getAccount();
};

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

export default msalApp;
