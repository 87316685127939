import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import 'moment/locale/es';
//import moment from 'moment/min/moment-with-locales';
import clsx from 'clsx';

//Icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CloseIcon from '@material-ui/icons/Close';

//Material UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  IconButton,
  InputBase,
  Button,
  TextField as MuiTextField,
  MenuItem,
  Grid,
  Select,
  useMediaQuery,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/styles';

//Palette
import palette from 'themes/palette';

//Components and Modules
import { ReserveForm } from 'modules/ReserveForm';
import { CustomPopUp } from 'components';

//Services
import { useSlotService } from 'services/SlotService';
import { useBranchService } from 'services/BranchService';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store';

import { useTranslation, Trans } from 'react-i18next';

const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Español' }
};


// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 8px',
    },
  },
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
    marginBottom: '15px',
    backgroundColor: 'white',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px',
    },
  },
  meetWithNwcu: {
    color: palette.highway.dark,
  },
  instructions: {
    marginTop: '10px',
    fontWeight: 300,
    marginRight: '15px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
      marginBottom: '5px',
      marginTop: '5px',
    },
  },
  leftTitleBox: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  dayOfWeek: {
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '11px',
    letterSpacing: '0.8px',
  },
  dateOfWeek: {
    fontSize: '20px',
    fontFamily: 'Roboto Slab',
    fontWeight: 400,
    letterSpacing: '0.5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '36px',
    lineHeight: '36px',
  },
  snackbar: { width: '100%' },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomerMainCalendarView = (props) => {

const localizer = momentLocalizer(moment);

  const classes = useStyles();
  const { match } = props;

  let branchId = match.params.id ? match.params.id.toLowerCase() : null;
  if (branchId === 'psc') {
    branchId = 'faf36ccc-909e-4866-831c-410422634f45';
  }

  // console.log(branchId);

  //Redux Hooks
  const dispatch = useDispatch();
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);
  const mode = useSelector((state) => state.reducer.mode);
  const rescheduleInfo = useSelector((state) => state.reducer.rescheduleInfo);

  //Service Hooks
  const slotService = useSlotService();
  const branchService = useBranchService();

  //State Hooks
  const [slotDialog, setSlotDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [customPopUp, setCustomPopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpContent, setPopUpContent] = useState('');
  const [popUpActions, setPopUpActions] = useState('');
  const [lastUpdatedTime, setLastUpdatedTime] = useState(moment().toDate());
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = React.useState(
    branchId
      ? branchId
      : selectedBranch.id || '1af36ccc-909e-4866-831c-410422634f45'
  ); // Setting to albany by default for now
  const [events, setEvents] = useState([]); //Events are empty before we get from API

  const today = moment().add(-7, 'day').utc().format('MM/d/YYYY');
  const fourWeeks = moment().add(27, 'week').utc().format('MM/d/YYYY');
  
  const { t, i18n } = useTranslation();

  //Get the events from the API
  useEffect(() => {
    slotService.get(branch, today, fourWeeks).then((response) => {
      const listOfEvents = [];
      response.data.map((event) => {
        let e = {
          id: event.id,
          branchId: event.branchId,
          start: moment(event.startDate)
            .add(
              momentTZ(event.startDate).tz('America/Los_Angeles').utcOffset(),
              'minutes'
            )
            .toDate(),
          end: moment(event.startDate)
            .add(event.duration, 'minutes')
            .add(
              momentTZ(event.startDate).tz('America/Los_Angeles').utcOffset(),
              'minutes'
            )
            .toDate(),
          title: 'Appointment',
          duration: event.duration,
          quantity: event.quantity,
          available: event.available,
          reservations: event.reservations,
        };
        listOfEvents.push(e);
      });

      //Update events state to contain the most recent list of events
      setEvents([...listOfEvents]);

      if (branch == 'faf36ccc-909e-4866-831c-410422634f45') {
        const newEvents = [...listOfEvents].filter(
          (event) => event.end.getHours() > 7 && event.end.getHours() < 19
        );
        // console.log('new events', newEvents);
        setEvents([...newEvents]);
      }
      if (branch == 'fbf36ccc-909e-4866-831c-410422634f45') {
        const newEvents = [...listOfEvents].filter(
          (event) => event.end.getHours() > 9 && event.end.getHours() < 17
        );
        // console.log('new events', newEvents);
        setEvents([...newEvents]);
      }
    });
  }, [lastUpdatedTime, branch]);

  //Checks if screen is xs or <
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const lang = moment.locale();

  useEffect(() => {
    //Get branches from API
    if (branches.length === 0)
      branchService.getBranches(lang).then((response) => {
        const filteredBranches = response.data.filter(
          (branch) => branch.isHidden === false
        );

        setBranches(filteredBranches);
      });
  }, []);

  // --- Slot Dialog Helpers --- //
  const openSlotDialog = () => setSlotDialog(true);
  const closeSlotDialog = () => {
    setLastUpdatedTime(moment().toDate());
    setSlotDialog(false);
  };

  //Custom PopUp Helpers
  const openCustomPopUp = () => setCustomPopUp(true);
  const closeCustomPopUp = () => setCustomPopUp(false);
  const closeCustomPopUpWithAction = (
    result,
    resultAction,
    functionParams = []
  ) => {
    resultAction(result, ...functionParams);
    setCustomPopUp(false);
  };

  //Changes events based on the dropdown
  const handleBranchChange = (event) => {
    //Get Branch Name
    const selectedBranchName = branches.find(
      (branch) => branch.id === event.target.value
    ).name;
    //Set local branch state
    setBranch(event.target.value);
    //Set branch in redux
    dispatch(
      actions.setSelectedBranch({
        name: selectedBranchName,
        id: event.target.value,
      })
    );
  };

  const leaveRescheduleMode = (result) => {
    if (result) {
      dispatch(actions.setMode('normal'));
      dispatch(actions.setRescheduleInfo({}));
    }
  };

  const leaveRescheduleModeButtonClick = () => {
    //Set popup content, title, and actions
    setPopUpTitle(
      <Box className={classes.flex}>
        <WarningRoundedIcon style={{ marginRight: '10px' }} fontSize="large" />
        Warning
      </Box>
    );
    setPopUpContent(i18n.t('areYouSureYouWantToLeaveRescheduleMode'));
    setPopUpActions(
      <Fragment>
        <Button
          onClick={() => {
            closeCustomPopUpWithAction(true, leaveRescheduleMode);
          }}
          variant="contained"
          color="primary"
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            closeCustomPopUp(false);
          }}
          variant="outlined"
          color="primary"
        >
          No
        </Button>
      </Fragment>
    );
    openCustomPopUp();
  };

  console.log('branches', branches);

  return (
    <div className={classes.outerContainer}>
      <CustomPopUp
        open={customPopUp}
        title={popUpTitle}
        actions={popUpActions}
        alert
        disableClose
      >
        {popUpContent}
      </CustomPopUp>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={mode === 'reschedule' ? true : false}
        // onClose={handleClose}
      >
        <SnackbarContent
          style={{ backgroundColor: palette.brick.main }}
          classes={{
            message: classes.snackbar,
          }}
          message={
            <Box
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {t('inRescheduleMode')} {rescheduleInfo.name}
              </div>
              <IconButton onClick={leaveRescheduleModeButtonClick}>
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </Box>
          }
        ></SnackbarContent>
      </Snackbar>
      <Box className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Box
              className={clsx(classes.titleBox)}
              style={{
                borderBottom: '1px solid #ededed',
                paddingBottom: '15px',
              }}
            >
              <Box className={classes.leftTitleBox}>
                <Box className={clsx('meetWithNwcu', classes.meetWithNwcu)}>
                  {mode === 'reschedule'
                    ? i18n.t('rescheduleAnAppointment')
                    : i18n.t('meet')}
                </Box>
                <Box className={classes.instructions}>
                  {mode === 'reschedule' ? (
                    <Box
                      style={{
                        border: '1px solid ' + palette.brick.main,
                        borderRadius: '10px',
                        padding: '10px',
                      }}
                    >
                      In{' '}
                      <span
                        style={{ color: palette.brick.main, fontWeight: 600 }}
                      >
                        i18n.t('rescheduleModeTitle')
                      </span>
                      i18n.t('rescheduleModeText')
                    </Box>
                  ) : (
                    i18n.t('click')
                  )}
                </Box>
                {branch == 'faf36ccc-909e-4866-831c-410422634f45' ? (
                  <Box
                    style={{
                      marginTop: '10px',
                      marginBottom: '5px',
                      backgroundColor: palette.mountain.main,
                      padding: '15px',
                      color: 'white',
                      borderRadius: '5px',
                      marginRight: '10px',
                    }}
                  >
                    <Box style={{ fontWeight: 800, marginBottom: '4px' }}>
                    {t('outsideHours')}
                    </Box>
                    <Box style={{ fontSize: '1.1rem' }}>
                      <span style={{ marginRight: '15px' }}>
                        <span style={{ fontWeight: 700, fontStyle: 'italic' }}>
                          Heather:{' '}
                        </span>
                        <a href="sms:+15412217556" style={{ color: 'white' }}>
                          (541) 221 - 7556
                        </a>
                      </span>{' '}
                      <span>
                        <span style={{ fontWeight: 700, fontStyle: 'italic' }}>
                          Nancy:{' '}
                        </span>
                        <a href="sms:+15413934836" style={{ color: 'white' }}>
                          (541) 393 - 4836
                        </a>
                      </span>
                    </Box>
                  </Box>
                ) : (
                  ''
                )}
                {branch == 'fbf36ccc-909e-4866-831c-410422634f45' ? (
                  <Box
                    style={{
                      marginTop: '10px',
                      marginBottom: '5px',
                      backgroundColor: palette.mountain.main,
                      padding: '15px',
                      color: 'white',
                      borderRadius: '5px',
                      marginRight: '10px',
                    }}
                  >
                    <Box style={{ fontWeight: 800, marginBottom: '4px' }}>
                      {t('outsideHours')}
                    </Box>
                    <Box style={{ fontSize: '1.1rem' }}>
                      <span style={{ marginRight: '15px' }}>
                        <span style={{ fontWeight: 700, fontStyle: 'italic' }}>
                          Matt:{' '}
                        </span>
                        <a href="sms:+19712680958" style={{ color: 'white' }}>
                          (971) 268 - 0958
                        </a>
                      </span>{' '}
                      <span>
                        <span style={{ fontWeight: 700, fontStyle: 'italic' }}>
                          Heather:{' '}
                        </span>
                        <a href="sms:+15412207556" style={{ color: 'white' }}>
                          (541) 221 - 7556
                        </a>
                      </span>
                    </Box>
                  </Box>
                ) : (
                  ''
                )}                
              </Box>
              <MuiTextField
                select
                id="branch"
                name="branch"
                label={t('branch')}
                variant="outlined"
                style={{ marginRight: '10px', minWidth: '250px' }}
                value={branch}
                onChange={handleBranchChange}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </MuiTextField>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Calendar
                localizer={localizer}
                min={new Date(2020, 1, 1, 9)} //start time at 10am, date doesn't matter
                max={new Date(2020, 1, 1, 17, 59)} //end time at 6pm, date doesn't matter
                views={['month', 'week', 'day']}
                drilldownView="null" //Makes headers not clickable.
                step={15} // Show 15 minute intervals
                timeslots={4} //Show 4 timeslots per hour
                components={{
                  event: Event,
                  week: {
                    header: ({ date, localizer }) => (
                      <WeekHeader date={date} localizer={localizer} />
                    ),
                  },
                  toolbar: (props) => <CalendarToolbar {...props} />,
                }}
                events={events}
                startAccessor="start"
                endAccessor="end"
                defaultView={'week'}
                style={{ height: 850 }}
                //Special styling for events
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    backgroundColor: '#51774D',
                    color: 'white',
                    border: 'none',
                    border: '1px solid white',
                  };

                  if (event.available === 0) {
                    newStyle.backgroundColor = '#cccccc';
                    newStyle.color = '#333333';
                  }
                  if (event.start < moment().toDate()) {
                    newStyle.opacity = '0.6';
                    newStyle.cursor = 'default';
                  }
                  if (event.available === 0) {
                    newStyle.cursor = 'default';
                  }

                  return {
                    className: '',
                    style: newStyle,
                  };
                }}
                //This is what happens when an event is clicked
                onSelectEvent={(event) => {
                  if (
                    event.start > moment().toDate() &&
                    event.available !== 0
                  ) {
                    openSlotDialog();
                    setDialogTitle(i18n.t('slotDetails'));
                    dispatch(actions.setSelectedEvent(event));
                    setDialogContent(
                      <Fragment>
                        <ReserveForm
                          close={closeSlotDialog}
                          branch={branch}
                          branches={branches}
                          setLastUpdated={setLastUpdatedTime}
                        />
                      </Fragment>
                    );
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={slotDialog} maxWidth="sm" fullScreen={fullScreen}>
        <DialogTitle>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3" color="primary">
              {dialogTitle}
            </Typography>
            <IconButton onClick={closeSlotDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box style={{ marginBottom: mode === 'reschedule' ? '90px' : '0' }}>
            {dialogContent}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomerMainCalendarView;

//Fake events to keep for testing just in case
const myEventsList = [
  {
    title: 'Appointment',
    start: new Date(2020, 3, 22, 12, 0, 0),
    end: new Date(2020, 3, 22, 13, 0, 0),
    quantity: 4,
    available: 2,
    members: ['Jim Halpert', 'Pam Beasly'],
  },
  {
    title: 'Appointment',
    start: new Date(2020, 3, 22, 13, 0, 0),
    end: new Date(2020, 3, 22, 13, 30, 0),
    quantity: 3,
    available: 0,
    members: ['Michael Scott', 'Dwight Schrute', 'Angela Martin'],
  },
  {
    title: 'Appointment',
    start: new Date(2020, 3, 23, 11, 0, 0),
    end: new Date(2020, 3, 23, 12, 0, 0),
    quantity: 3,
    available: 1,
    members: ['Andy Bernard', 'Karen Filippelli'],
  },
  {
    title: 'Appointment',
    start: new Date(2020, 3, 24, 12, 0, 0),
    end: new Date(2020, 3, 24, 12, 30, 0),
    quantity: 5,
    available: 2,
    members: ['Kelly Kapoor', 'Ryan Howard', 'Toby Flenderson'],
  },
  {
    title: 'Appointment',
    start: new Date(2020, 3, 23, 12, 0, 0),
    end: new Date(2020, 3, 23, 13, 0, 0),
    quantity: 4,
    available: 2,
    members: ['Jim Halpert', 'Pam Beasly'],
  },
  {
    title: 'Appointment',
    start: new Date(2020, 3, 24, 13, 15, 0),
    end: new Date(2020, 3, 24, 13, 45, 0),
    quantity: 4,
    available: 2,
    members: ['Jim Halpert', 'Pam Beasly'],
  },
  {
    title: 'Appointment',
    start: new Date(2020, 3, 24, 14, 0, 0),
    end: new Date(2020, 3, 24, 14, 30, 0),
    quantity: 4,
    available: 2,
    members: ['Jim Halpert', 'Pam Beasly'],
  },
  {
    title: 'Appointment',
    start: new Date(2020, 3, 24, 14, 45, 0),
    end: new Date(2020, 3, 24, 15, 15, 0),
    quantity: 4,
    available: 2,
    members: ['Jim Halpert', 'Pam Beasly'],
  },
];

const Event = ({ event }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div style={{ fontWeight: 500, fontSize: '12px', lineHeight: '15px' }}>
        <span
          style={{
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            letterSpacing: '0.1px',
          }}
        >
          {event.available === 0 ? i18n.t('unavailable') : i18n.t('available')}
        </span>
      </div>
    </div>
  );
};

const WeekHeader = (props) => {
  const useStyles = makeStyles((theme) => ({
    dayOfWeek: {
      fontWeight: 500,
      textTransform: 'uppercase',
      fontSize: '11px',
      letterSpacing: '0.8px',
    },
    dateOfWeek: {
      fontSize: '20px',
      fontFamily: 'Roboto Slab',
      fontWeight: 400,
      letterSpacing: '0.5px',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '36px',
      lineHeight: '36px',
    },
    headerContainer: {
      padding: '10px 0',
      cursor: 'default',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.dayOfWeek}>
        {props.localizer.format(props.date, 'ddd')}
      </div>
      <div className={classes.dateOfWeek}>
        {props.localizer.format(props.date, 'D')}
      </div>
    </div>
  );
};

//This is a smaller dropdown with less padding
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);

const CalendarToolbar = (props) => {
  const useStyles = makeStyles((theme) => ({
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    titleArrowBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    calendarTitle: {
      color: palette.highway.main,
      fontSize: '28px',
      margin: '0 15px',
      // fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: '22px',
      },
    },
    hidden: {
      visibility: 'hidden',
      marginRight: 'auto',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    addSlot: {
      marginRight: 'auto',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,

        display: 'none',
      },
    },
    addSlot2: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
        marginRight: '10px',
      },
    },
    changeView: {
      marginLeft: 'auto',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
  }));
  const classes = useStyles();

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const currentView = useSelector((state) => state.reducer.currentView);

  const { t, i18n } = useTranslation();

  //   useEffect(() => {
  //     setCalendarView(isMobile ? 'day' : 'week');
  //     props.onView(isMobile ? 'day' : 'week');
  //   }, [isMobile]);

  const handleViewChange = (view) => {
    dispatch(actions.setCurrentView(view));
    props.onView(view);
  };
  return (
    <div className={classes.toolbar}>
      {/* Use the following select for customer version (w/o the add appt button)
        & remove the box w classes.changeView and move that class to just the
        select and remove both add appt buttons */}
      <Select
        className={classes.hidden}
        variant="outlined"
        value={currentView}
        onChange={(event) => handleViewChange(event.target.value)}
      >
        {/* <MenuItem value={'month'}>Month</MenuItem> */}
        <MenuItem value={'week'}>{t('week')}</MenuItem>
        <MenuItem value={'day'}>{t('day')}</MenuItem>
      </Select>
      {/* <Button
        size="small"
        color="secondary"
        variant="contained"
        className={classes.addSlot}
        onClick={props.addSlot}
      >
        + Add Slot
      </Button> */}
      <Box className={classes.titleArrowBox}>
        <IconButton
          className="toolbar-navigation-button"
          type="button"
          onClick={() => props.onNavigate('PREV')}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <span
          className={clsx('calendarTitle', classes.calendarTitle)}
          //   onClick={() => props.onNavigate('TODAY')}
        >
          {props.label}
        </span>
        <IconButton
          className="toolbar-navigation-button"
          type="button"
          onClick={() => props.onNavigate('NEXT')}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
      <Box className={classes.changeView}>
        {/* Remove button below and box above for customer version. 
          Move changeView class into the select */}
        {/* <Button
          size="small"
          color="secondary"
          variant="contained"
          className={classes.addSlot2}
          onClick={props.addSlot}
        >
          + Add Slot
        </Button> */}
        <Select
          variant="outlined"
          value={currentView}
          onChange={(event) => handleViewChange(event.target.value)}
          input={<BootstrapInput />}
        >
          {/* <MenuItem value={'month'}>Month</MenuItem> */}
          <MenuItem value={'week'}>{t('week')}</MenuItem>
        <MenuItem value={'day'}>{t('day')}</MenuItem>
        </Select>
      </Box>
    </div>
  );
};
