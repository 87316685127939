import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';

import moment from 'moment';
import 'moment/locale/es';
import clsx from 'clsx';

//Icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import VideocamIcon from '@material-ui/icons/Videocam';
import AppsIcon from '@material-ui/icons/Apps';
import AddIcon from '@material-ui/icons/Add';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ChatIcon from '@material-ui/icons/Chat';
import ClearIcon from '@material-ui/icons/Clear';

//Material UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  IconButton,
  InputBase,
  Button,
  TextField as MuiTextField,
  MenuItem,
  Grid,
  Select,
  useMediaQuery,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/styles';

import { useTranslation, Trans } from 'react-i18next';

//Palette
import palette from 'themes/palette';

//Components and Modules
import { CustomPopUp } from 'components';
import { CurrentReservations } from 'modules/CurrentReservations';
import { EditSlot } from 'modules/EditSlot';
import { ReserveForm } from 'modules/ReserveForm';
import { AddSlot } from 'modules/AddSlot';
import { SlotDetails } from 'modules/SlotDetails';

//Services
import { useSlotService } from 'services/SlotService';
import { useBranchService } from 'services/BranchService';
import { useTemplateService } from 'services/TemplateService';
import { useAuth } from 'services/AuthService';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store';


const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Español' }
};
const localizer = momentLocalizer(moment);
//moment.locale(i18n.locale)

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 8px',
    },
  },
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
    marginBottom: '15px',
    backgroundColor: 'white',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px',
    },
  },
  meetWithNwcu: {
    color: palette.highway.dark,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  instructions: {
    marginTop: '10px',
    fontWeight: 300,
    marginRight: '15px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
      marginBottom: '5px',
      marginTop: '5px',
    },
  },
  leftTitleBox: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  dayOfWeek: {
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '11px',
    letterSpacing: '0.8px',
  },
  dateOfWeek: {
    fontSize: '20px',
    fontFamily: 'Roboto Slab',
    fontWeight: 400,
    letterSpacing: '0.5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '36px',
    lineHeight: '36px',
  },
  snackbar: { width: '100%' },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function maskPhoneNumber(string) {
  return (
    '(' +
    string.slice(0, 3) +
    ') ' +
    string.slice(3, 6) +
    '-' +
    string.slice(6, 10)
  );
}

const MainCalendarView = (props) => {
  const classes = useStyles();
  const { history } = props;

  //Service Hooks
  const slotService = useSlotService();
  const branchService = useBranchService();
  const templateService = useTemplateService();
  const { isAuthenticated, getTokenForApi, appSettings } = useAuth();

  //State Hooks
  const [slotDialog, setSlotDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [customPopUp, setCustomPopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpContent, setPopUpContent] = useState('');
  const [popUpActions, setPopUpActions] = useState('');
  const [lastUpdatedTime, setLastUpdatedTime] = useState(moment().toDate());
  const [events, setEvents] = useState([]); //Events are empty before we get from API
  const [scrollHeight, setScrollHeight] = useState(800);
  const [displayApplyToWeek, setDisplayApplyToWeek] = useState(false);

  const { t, i18n } = useTranslation();
  
  //////
  let thissunday = moment(moment().toDate()).utc().startOf('week');
  if (moment(moment().toDate()).isDST()) {
    thissunday = thissunday.clone().subtract(1, 'hours');
  }
  const [startOfWeek, setStartOfWeek] = useState(thissunday);
  ///////

  //Redux Hooks
  const dispatch = useDispatch();
  const branches = useSelector((state) => state.reducer.branches);
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);
  const currentView = useSelector((state) => state.reducer.currentView);
  const mode = useSelector((state) => state.reducer.mode);
  const rescheduleInfo = useSelector((state) => state.reducer.rescheduleInfo);

  const today = moment().add(-7, 'day').utc().format('MM/d/YYYY');
  const fourWeeks = moment().add(27, 'week').utc().format('MM/d/YYYY');



  //Get the events from the API
  useEffect(() => {
    slotService.get(selectedBranch.id, today, fourWeeks).then((response) => {
      const listOfEvents = [];
      response.data.map((event) => {
        let e = {
          id: event.id,
          branchId: event.branchId,
          start: moment(event.startDate).parseZone().toDate(),
          end: moment(event.startDate)
            .add(event.duration, 'minutes')
            .parseZone()
            .toDate(),
          title: 'Appointment',
          duration: event.duration,
          quantity: event.quantity,
          available: event.available,
          reservations: event.reservations,
        };
        listOfEvents.push(e);
      });

      //Update events state to contain the most recent list of events
      setEvents([...listOfEvents]);
    });
  }, [lastUpdatedTime, selectedBranch]);

  //This sets the height of the calendar for agenda view
  useEffect(() => {
    setTimeout(() => {
      var calendar = document.getElementById('calendar');
      if (calendar) {
        var calChildren = calendar.children[1];
        var calChildrenChildren = calChildren.children[1];
        if (calChildrenChildren) {
          const height = calChildrenChildren.scrollHeight;
          setScrollHeight(height + 120);
        } else {
          setScrollHeight(140);
        }
      }
    }, 300);
  }, [currentView, selectedBranch, lastUpdatedTime]);

  //Redirect to select a branch if no default is set
  useEffect(() => {
    if (isAuthenticated) {
      if (appSettings.defaultBranch && appSettings.defaultBranchName) {
        //Set selected branch in redux
        dispatch(
          actions.setSelectedBranch({
            name: appSettings.defaultBranchName,
            id: appSettings.defaultBranch,
          })
        );
      } else {
        //Redirect to calendar page
        history.push({
          pathname: '/select-a-branch',
        });
      }
    }
  }, [appSettings, isAuthenticated]);

  //Puts apply to week button on first week if no events
  useEffect(() => {
    let sunday = moment(startOfWeek);
    //We need to check to see if there are any events on this week
    slotService
      .get(
        selectedBranch.id,
        sunday.toDate(),
        sunday.clone().add(7, 'days').toDate()
      )
      .then((response) => {
        // console.log('resp', response);
        if (response.status === 200) {
          //If there are no events in this current week:
          if (response.data.length === 0) {
            //show apply template button
            setDisplayApplyToWeek(true);
            // setStartOfWeek(sunday.toDate());
          } else {
            //Otherwise, hide the Apply Template button
            setDisplayApplyToWeek(false);
          }
        }
      });
  }, [lastUpdatedTime, startOfWeek, selectedBranch]);

  //Checks if screen is xs or <
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // --- Slot Dialog Helpers --- //
  const openSlotDialog = () => setSlotDialog(true);
  const closeSlotDialog = () => {
    setLastUpdatedTime(moment().toDate());
    setSlotDialog(false);
  };

  //Custom PopUp Helpers
  const openCustomPopUp = () => setCustomPopUp(true);
  const closeCustomPopUp = () => setCustomPopUp(false);
  const closeCustomPopUpWithAction = (
    result,
    resultAction,
    functionParams = []
  ) => {
    resultAction(result, ...functionParams);
    setCustomPopUp(false);
  };

  //Brings up the dialog when add slot button is clicked
  const addSlotButtonClick = () => {
    openSlotDialog();
    setDialogTitle('Add Slot to ' + selectedBranch.name + "'s Calendar");
    setDialogContent(
      <Fragment>
        <AddSlot
          close={closeSlotDialog}
          slotService={slotService}
          type="button"
        />
      </Fragment>
    );
  };

  //Changes events based on the dropdown
  const handleBranchChange = (event) => {
    //Get Branch Name
    const selectedBranchName = branches.find(
      (branch) => branch.id === event.target.value
    ).name;
    //Set branch in redux
    dispatch(
      actions.setSelectedBranch({
        name: selectedBranchName,
        id: event.target.value,
      })
    );
  };

  const leaveRescheduleMode = (result) => {
    if (result) {
      dispatch(actions.setMode('normal'));
      dispatch(actions.setRescheduleInfo({}));
    }
  };

  //If the x is clicked in reschedule mode popup:
  const leaveRescheduleModeButtonClick = () => {
    setPopUpTitle(
      <Box className={classes.flex}>
        <WarningRoundedIcon style={{ marginRight: '10px' }} fontSize="large" />
        {t('warning')}
      </Box>
    );
    setPopUpContent(i18n.t('areYouSureYouWantToLeaveRescheduleMode'));
    setPopUpActions(
      <Fragment>
        <Button
          onClick={() => {
            closeCustomPopUpWithAction(true, leaveRescheduleMode);
          }}
          variant="contained"
          color="primary"
        >
          {t('yes')}
        </Button>
        <Button
          onClick={() => {
            closeCustomPopUp(false);
          }}
          variant="outlined"
          color="primary"
        >
          {t('no')}
        </Button>
      </Fragment>
    );
    openCustomPopUp();
  };

  const applyTemplateToWeek = () => {
    getTokenForApi()
      .then((token) => {
        if (isAuthenticated && token !== '') {
          templateService
            .applyTemplateToWeek(selectedBranch.id, startOfWeek, token)
            .then((response) => {
              //This triggers events to rerender
              setLastUpdatedTime(moment().toDate());
            });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    setDisplayApplyToWeek(false);
  };

  return (
    <div className={classes.outerContainer}>
      <CustomPopUp
        open={customPopUp}
        title={popUpTitle}
        actions={popUpActions}
        alert
        disableClose
      >
        {popUpContent}
      </CustomPopUp>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={mode === 'reschedule' ? true : false}
        // onClose={handleClose}
      >
        <SnackbarContent
          style={{ backgroundColor: palette.brick.main }}
          classes={{
            message: classes.snackbar,
          }}
          message={
            <Box
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {t('inRescheduleMode')} {rescheduleInfo.name}
              </div>
              <IconButton onClick={leaveRescheduleModeButtonClick}>
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </Box>
          }
        ></SnackbarContent>
      </Snackbar>
      <Box className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Box
              className={clsx(classes.titleBox)}
              style={{
                borderBottom: '1px solid #ededed',
                paddingBottom: '15px',
              }}
            >
              <Box className={classes.leftTitleBox}>
                <Box className={clsx('meetWithNwcu', classes.meetWithNwcu)}>
                  {mode === 'reschedule'
                    ? i18n.t('rescheduleAnAppointment')
                    : i18n.t('meet')}
                </Box>
                <Box className={classes.instructions}>
                  {mode === 'reschedule' ? (
                    <Box
                      style={{
                        border: '1px solid ' + palette.brick.main,
                        borderRadius: '10px',
                        padding: '10px',
                      }}
                    >
                      In{' '}
                      <span
                        style={{ color: palette.brick.main, fontWeight: 600 }}
                      >
                        {t('rescheduleModeTitle')}
                      </span>
                      {t('rescheduleModeText')}
                    </Box>
                  ) : (
                    <span>
                      {' '}
                      Click on any future appointment to fill out the form to
                      reserve an appointment for a customer, view current
                      reservations, cancel or reschedule reservations, or edit
                      the number of appointments in a slot.{' '}
                    </span>
                  )}
                </Box>
              </Box>
              <MuiTextField
                select
                id="branch"
                name="branch"
                label={t('branch')}
                variant="outlined"
                style={{ marginRight: '10px', minWidth: '250px' }}
                value={selectedBranch.id}
                onChange={handleBranchChange}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </MuiTextField>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Calendar
                elementProps={{ id: 'calendar' }}
                localizer={localizer}
                defaultDate={moment().startOf('day')} //Makes it so current time is beginning of day, useful for agenda view
                min={new Date(2020, 1, 1, 9)} //start time at 9am, date doesn't matter
                max={new Date(2020, 1, 1, 17, 59)} //end time at 6pm, date doesn't matter
                views={['month', 'week', 'day', 'agenda']}
                length={7} //number of days to show per interval in agenda
                step={15} // Show 15 minute intervals
                timeslots={4} //Show 4 timeslots per hour
                popup={true} //For month view, shows extra events in popup
                drilldownView={null} //Makes headers not clickable
                components={{
                  event: Event,
                  week: {
                    header: ({ date, localizer }) => (
                      <WeekHeader date={date} localizer={localizer} />
                    ),
                  },
                  toolbar: (props) => (
                    <CalendarToolbar
                      addSlot={addSlotButtonClick}
                      displayApply={displayApplyToWeek}
                      applyTemplateToWeek={applyTemplateToWeek}
                      {...props}
                    />
                  ),
                  agenda: {
                    event: EventAgenda,
                  },
                }}
                events={events}
                startAccessor="start"
                endAccessor="end"
                defaultView={'week'}
                style={{
                  height: currentView !== 'agenda' ? 850 : scrollHeight,
                }}
                //Special styling for events
                eventPropGetter={(event, start, end, isSelected) => {
                  if (currentView !== 'agenda') {
                    let newStyle = {
                      backgroundColor: '#51774D',
                      color: 'white',
                      border: 'none',
                      border: '1px solid white',
                    };

                    if (event.available === 0) {
                      newStyle.backgroundColor = '#cccccc';
                      newStyle.color = '#333333';
                    }
                    if (event.end < moment().toDate()) {
                      newStyle.opacity = '0.5';
                      // newStyle.cursor = 'default';
                    }

                    return {
                      className: '',
                      style: newStyle,
                    };
                  }
                }}
                //This is what happens when an event is clicked
                onSelectEvent={(event) => {
                  //Future events
                  if (event.end > moment().toDate()) {
                    openSlotDialog();
                    setDialogTitle(i18n.t('slotDetails'));
                    dispatch(actions.setSelectedEvent(event));
                    setDialogContent(
                      <Fragment>
                        <ReserveForm
                          close={closeSlotDialog}
                          setLastUpdated={setLastUpdatedTime}
                        />
                        <CurrentReservations close={closeSlotDialog} />
                        <EditSlot
                          slotService={slotService}
                          close={closeSlotDialog}
                        />
                      </Fragment>
                    );
                  }
                  //Past events
                  else {
                    openSlotDialog();
                    setDialogTitle(i18n.t('slotDetails'));
                    dispatch(actions.setSelectedEvent(event));
                    setDialogContent(
                      <Fragment>
                        <SlotDetails />
                        <CurrentReservations close={closeSlotDialog} />
                      </Fragment>
                    );
                  }
                }}
                selectable
                //This is what happens when you drag to select an event time
                onSelectSlot={(event) => {
                  openSlotDialog();
                  setDialogTitle(
                    'Add Slot to ' + selectedBranch.name + "'s Calendar"
                  );
                  dispatch(actions.setAddEventData(event));
                  setDialogContent(
                    <Fragment>
                      <AddSlot
                        close={closeSlotDialog}
                        slotService={slotService}
                        type="drag"
                      />
                    </Fragment>
                  );
                }}
                onNavigate={(date) => {
                  //First, we need to get the sunday of the current week
                  let sunday = moment(date).utc().startOf('week');
                  //And if it is DST, subtract one hour because of weird time zone math with the template
                  if (moment(date).isDST()) {
                    sunday = sunday.clone().subtract(1, 'hours');
                  }
                  setStartOfWeek(sunday.toDate());

                  //We need to check to see if there are any events on this week
                  // slotService
                  //   .get(
                  //     selectedBranch.id,
                  //     sunday.toDate(),
                  //     sunday.clone().add(7, 'days').toDate()
                  //   )
                  //   .then((response) => {
                  //     // console.log('resp:', response);
                  //     if (response.status === 200) {
                  //       //If there are no events in this current week:
                  //       if (response.data.length === 0) {
                  //         //show apply template button
                  //         setDisplayApplyToWeek(true);
                  //         console.log('setting start of week', sunday.toDate());
                  //       } else {
                  //         //Otherwise, hide the Apply Template button
                  //         setDisplayApplyToWeek(false);
                  //       }
                  //     }
                  //   });
                  // setLastUpdatedTime(moment().toDate());
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={slotDialog} maxWidth="sm" fullScreen={fullScreen}>
        <DialogTitle>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3" color="primary">
              {dialogTitle}
            </Typography>
            <IconButton onClick={closeSlotDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box style={{ marginBottom: mode === 'reschedule' ? '90px' : '0' }}>
            {dialogContent}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MainCalendarView;

const EventAgenda = ({ event }) => {
  //Redux hooks
  const purposes = useSelector((state) => state.reducer.purposes);

  const { t, i18n } = useTranslation();

  const useStyles = makeStyles((theme) => ({
    title: {
      fontSize: '130%',
      color: palette.forest.main,
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    currentReservations: {
      color: palette.river.main,
      fontSize: '24px',
      fontWeight: 500,
      paddingBottom: '12px',
      marginTop: '15px',
    },
    reservationInfo: {
      borderLeft: '3px solid ' + palette.forest.main,
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingLeft: '10px',
      fontWeight: 300,
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
      marginBottom: '10px',
      // marginLeft: '15px',
      // flexWrap: 'wrap',
    },
    attendeeName: {
      fontWeight: '500',
      color: palette.forest.dark,
      fontSize: '120%',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '15px',
      '&:not(:first-child)': {
        marginTop: '5px',
      },
    },
    icon: {
      marginRight: '5px',
      color: palette.highway.main,
    },
  }));
  const classes = useStyles();

  function maskPhoneNumber(string) {
    return (
      '(' +
      string.slice(0, 3) +
      ') ' +
      string.slice(3, 6) +
      '-' +
      string.slice(6, 10)
    );
  }

  return (
    <Box style={{ padding: '10px' }}>
      <Box className={classes.title}>
        {event.title}
        {event.available !== 0
          ? ' - ' + event.available + '/' + event.quantity + ' ' + i18n.t('available')
          : ' - ' + i18n.t('unavailable')}
      </Box>
      {event.reservations.length !== 0 ? (
        <Box className={classes.currentReservations}>Current Reservations</Box>
      ) : (
        ''
      )}
      {/* {event.reservations.map((reservation) => {
        return (
          <div className={classes.reservationInfo}>
            <Box className={classes.item}>
              <PersonIcon className={classes.icon} fontSize="small" />
              {reservation.name}
            </Box>{' '}
            <Box className={classes.item}>
              <EmailIcon className={classes.icon} fontSize="small" />
              {reservation.email}
            </Box>
            <Box className={classes.item}>
              <PhoneIcon className={classes.icon} fontSize="small" />
              {maskPhoneNumber(reservation.phoneNumber)}
            </Box>
          </div>
        );
      })} */}
      <Grid container>
        {event.reservations.map((reservation) => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              className={classes.reservationInfo}
            >
              {/* <PersonIcon style={{ marginRight: '10px' }} />{' '} */}
              <Box className={clsx(classes.attendeeName, classes.item)}>
                <PersonIcon className={classes.icon} fontSize="small" />
                {reservation.name}
              </Box>{' '}
              <Box className={classes.item}>
                <EmailIcon className={classes.icon} fontSize="small" />
                {reservation.email}
              </Box>
              <Box className={classes.item}>
                <PhoneIcon className={classes.icon} fontSize="small" />
                {maskPhoneNumber(reservation.phoneNumber)}
              </Box>
              <Box className={classes.item}>
                <ChatBubbleIcon className={classes.icon} fontSize="small" />
                {
                  purposes.find(
                    (purpose) => purpose.id === reservation.purposeId
                  ).title
                }
              </Box>
              {/* <Box className={classes.item}>
                <ChatIcon className={classes.icon} fontSize="small" />
                {reservation.comment !== ''
                  ? reservation.comment
                  : 'No Comment provided'}
              </Box> */}
              <Box className={classes.item}>
                <i
                  className={clsx('fas fa-hashtag', classes.icon)}
                  style={{
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                ></i>
                {reservation.memberNumber !== ''
                  ? reservation.memberNumber
                  : i18n.t('noMemberNumberProvided')}
              </Box>
              {reservation.isVideo ? (
                <Box className={clsx(classes.item)}>
                  <VideocamIcon className={classes.icon} fontSize="small" />
                  i18n.('videoAppointment')
                </Box>
              ) : (
                ''
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const Event = ({ event }) => {
  return (
    <div>
      <div style={{ fontWeight: 500, fontSize: '12px', lineHeight: '15px' }}>
        <div
          style={{
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            letterSpacing: '0.1px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* {event.end < moment().toDate() ? (
            <ClearIcon style={{ fontSize: 15 }} />
          ) : (
            ''
          )} */}
          <div>
            {event.available}/{event.quantity} -{' '}
            {event.available === 0 ? 'Unavailable' : 'Available'}
          </div>
        </div>
      </div>
    </div>
  );
};

const WeekHeader = (props) => {
  const classes = useStyles();

  return (
    <div style={{ padding: '10px 0' }}>
      <div className={classes.dayOfWeek}>
        {props.localizer.format(props.date, 'ddd')} hi
      </div>
      <div className={classes.dateOfWeek}>
        {props.localizer.format(props.date, 'D')} by
      </div>
    </div>
  );
};

//This is a smaller dropdown with less padding
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);

const CalendarToolbar = (props) => {
  const useStyles = makeStyles((theme) => ({
    toolbar: {
      marginBottom: '10px',
    },
    titleArrowBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    calendarTitle: {
      color: palette.highway.main,
      textAlign: 'center',
      fontSize: '28px',
      margin: '0 15px',
      // fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '25px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '22px',
      },
    },
    left: {
      paddingRight: '10px',
      '& > *': {
        margin: '4px',
      },
      [theme.breakpoints.down('xs')]: {
        order: 2,
        textAlign: 'center',
      },
    },
    middle: {
      [theme.breakpoints.down('xs')]: {
        order: 1,
      },
    },
    right: {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        order: 3,
        textAlign: 'center',
      },
    },
  }));
  const classes = useStyles();

  //Hooks
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const currentView = useSelector((state) => state.reducer.currentView);

  const { t, i18n } = useTranslation();

  //   useEffect(() => {
  //     setCalendarView(isMobile ? 'day' : 'week');
  //     props.onView(isMobile ? 'day' : 'week');
  //   }, [isMobile]);

  const handleViewChange = (view) => {
    dispatch(actions.setCurrentView(view));
    props.onView(view);
  };
  return (
    <Box>
      <Box className={classes.toolbar}>
        <Grid container alignItems="center">
          <Grid item xs={6} sm={3} lg={4} className={classes.left}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              className={classes.addSlot}
              onClick={props.addSlot}
            >
              <AddIcon style={{ marginRight: '5px' }} /> Add Slot
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              style={{
                display: props.displayApply
                  ? currentView === 'week'
                    ? 'inline-flex'
                    : 'none'
                  : 'none',
              }}
              onClick={props.applyTemplateToWeek}
            >
              <AppsIcon style={{ marginRight: '5px' }} /> Apply Template to Week
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.middle}>
            <Box className={classes.titleArrowBox}>
              <IconButton
                className="toolbar-navigation-button"
                type="button"
                onClick={() => props.onNavigate('PREV')}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <span
                className={clsx('calendarTitle', classes.calendarTitle)}
                //   onClick={() => props.onNavigate('TODAY')}
              >
                {props.label}
              </span>
              <IconButton
                className="toolbar-navigation-button"
                type="button"
                onClick={() => props.onNavigate('NEXT')}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} lg={4} className={classes.right}>
            <Select
              variant="outlined"
              value={currentView}
              onChange={(event) => handleViewChange(event.target.value)}
              input={<BootstrapInput />}
            >
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="week">{t('week')}</MenuItem>
              <MenuItem value="day">{t('day')}</MenuItem>
              <MenuItem value="agenda">Agenda</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
