// --- Yup/Validation Imports --- //
import * as yup from 'yup';
import { phone } from 'utilities/validation/regex';

const AddSlotSchema = yup.object().shape({
  duration: yup.string().label('Duration').required(),
  startTime: yup.string().label('Start Time').required(),
  date: yup.date().label('Date').required(),
  quantity: yup
    .number()
    .integer()
    .positive()
    .label('Number of Appointments')
    .required(),
});

export default AddSlotSchema;
