import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';

//Components
import { Subtitle, TextMaskCustom, CustomPopUpAlert } from 'components';

//Material UI
import {
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  MenuItem,
  Grid,
  Chip,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Redux
import { useSelector, useDispatch } from 'react-redux';

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    marginBottom: '15px',
  },
  chipContainer: {
    '& > *': {
      margin: theme.spacing(0.5),
      // backgroundColor: palette.forest.main,
      // color: 'white',
    },
  },
  chip: {
    marginRight: '5px',
  },
}));

const SlotDetails = (props) => {
  const classes = useStyles();

  const selectedEvent = useSelector((state) => state.reducer.selectedEvent);
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);

  return (
    <Box className={classes.box}>
      <Subtitle>Slot Details</Subtitle>
      <Box className={classes.chipContainer}>
        <Chip
          label={moment(selectedEvent.start).format('dddd, MMMM D, YYYY')}
          size="small"
        />
        <Chip
          label={moment(selectedEvent.start).format('hh:mm A')}
          size="small"
        />
        <Chip
          label={
            moment(selectedEvent.end).diff(selectedEvent.start, 'minutes') +
            ' minutes'
          }
          size="small"
        />
        <Chip label={selectedBranch.name + ' Branch'} size="small" />
        <Chip
          label={
            selectedEvent.available +
            ' ' +
            (selectedEvent.available === 1 ? 'appointment ' : 'appointments ') +
            'available'
          }
          size="small"
        />
      </Box>
    </Box>
  );
};

export default SlotDetails;
