import React, { useState, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';
import 'assets/scss/branches.scss';
import palette from 'themes/palette';

//Material UI
import {
  useMediaQuery,
  Box,
  IconButton,
  Button,
  ButtonBase,
  CircularProgress,
  Link,
  TextField as MuiTextField,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/styles';

//Icon
import LocationOnIcon from '@material-ui/icons/LocationOn';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';

//Services
import { useBranchService } from 'services/BranchService';

import { useTranslation, Trans } from 'react-i18next';

const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Español' }
};

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  //BranchView Styles
  root: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    margin: '30px',
    [theme.breakpoints.down('xs')]: {
      margin: '15px 10px',
    },
  },
  title: {
    color: palette.highway.main,
  },
  instructions: {
    marginTop: '10px',
    fontWeight: 300,
    marginBottom: '5px',
  },
  //BranchBox Styles
  button: {
    width: '100%',
  },
  boxContainer: {
    width: '100%',
    border: '1px solid #dddddd',
    borderRadius: '15px',
    backgroundColor: 'white',
    overflow: 'hidden',
    // Hover styles can be found in branches.scss
  },

  name: {
    fontWeight: '600',
    color: 'white',
    fontSize: '22px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    padding: '15px',
    backgroundColor: palette.forest.main,
  },
  premierName: {
    fontWeight: '600',
    color: 'white',
    fontSize: '22px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    padding: '15px',
    backgroundColor: palette.mountain.main,
  },
  addressBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
  address: {
    fontWeight: 300,
    fontFamily: 'Roboto',
    textAlign: 'left',
    fontSize: '16px',
  },
}));

const BranchBox = (props) => {


  const classes = useStyles();

  const { branch, ...rest } = props;
  // console.log('branch', branch);

  return (
    <div
      className={
        branch.id == 'faf36ccc-909e-4866-831c-410422634f45' || branch.id == 'fbf36ccc-909e-4866-831c-410422634f45'
          ? clsx('premierBoxContainer', classes.boxContainer)
          : clsx('boxContainer', classes.boxContainer)
      }
    >
      <Box
        className={
          branch.id == 'faf36ccc-909e-4866-831c-410422634f45' || branch.id == 'fbf36ccc-909e-4866-831c-410422634f45'
            ? clsx('name', classes.premierName)
            : clsx('name', classes.name)
        }
      >
        {' '}
        {branch.name}
      </Box>
      <Box className={classes.addressBox}>
        <LocationOnIcon
          style={{
            marginRight: '10px',
            color:
              branch.id == 'faf36ccc-909e-4866-831c-410422634f45' || branch.id == 'fbf36ccc-909e-4866-831c-410422634f45'
                ? '#bf8450'
                : palette.river.main,
          }}
          // color="secondary"
        />
        <Box className={classes.address}>
          {branch.addressLine1}
          <br />
          {branch.city}, {branch.state} {branch.postalCode}
        </Box>
      </Box>
    </div>
  );
};

const BranchView = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { history } = props;

  const { t, i18n } = useTranslation();

  //State Hooks
  const [loading, setLoading] = useState(true);
  const [nonHiddenBranches, setNonHiddenBranches] = useState([]);
  //Service Hooks
  const branchService = useBranchService();

  //Redux Hooks
  const dispatch = useDispatch();

  //Get branches from redux
  const branches = useSelector((state) => state.reducer.branches);

  useEffect(() => {
    if (branches !== []) {
      setLoading(false);
      setNonHiddenBranches(
        branches.filter((branch) => branch.isHidden === false)
      );
    }
  }, [branches]);

  const branchOnClick = (branch) => {
    //Set selected branch in redux
    dispatch(actions.setSelectedBranch({ name: branch.name, id: branch.id }));

    //Redirect to calendar page
    history.push({
      pathname: '/calendar',
    });
  };

  return (
    <div className={classes.root}>
      {loading === true ? (
        //Show spinner
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            style={{ marginBottom: '20px' }}
            color="secondary"
          />
          {t('loading')}
        </Box>
      ) : (
        <Box className={classes.container}>
          <Grid container spacing={mobileScreen ? 2 : 3}>
            <Grid item xs={12}>
              <Box className={clsx('pageTitle', classes.title)}>
                {t('schedule')}
              </Box>
              <Box className={classes.instructions}>
                {t('branchChoose')}{' '}
              </Box>
            </Grid>
            {nonHiddenBranches.map((branch, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <ButtonBase
                    focusRipple
                    className={classes.button}
                    onClick={() => branchOnClick(branch)}
                  >
                    <BranchBox branch={branch} />
                  </ButtonBase>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default BranchView;
