import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import 'assets/scss/template.scss';

//Icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import AppsIcon from '@material-ui/icons/Apps';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

//Material UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  IconButton,
  InputBase,
  Button,
  TextField as MuiTextField,
  MenuItem,
  Grid,
  Select,
  useMediaQuery,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/styles';

//Palette
import palette from 'themes/palette';
import brick from 'themes/colors/brick';

//Components and Modules
import { CustomPopUp } from 'components';
import { AddTemplateSlot } from 'modules/AddTemplateSlot';
import { ResetTemplateCalendar } from 'modules/ResetTemplateCalendar';
import { EditTemplateSlot } from 'modules/EditTemplateSlot';

//Services
import { useAuth } from 'services/AuthService';
import { useTemplateService } from 'services/TemplateService';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store';

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 8px',
    },
  },
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
    marginBottom: '15px',
    backgroundColor: 'white',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px',
    },
  },
  meetWithNwcu: {
    color: palette.highway.dark,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  instructions: {
    marginTop: '10px',
    fontWeight: 300,
    marginRight: '15px',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
      marginBottom: '5px',
      marginTop: '5px',
    },
  },
  leftTitleBox: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  dayOfWeek: {
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '11px',
    letterSpacing: '0.8px',
  },
  dateOfWeek: {
    fontSize: '20px',
    fontFamily: 'Roboto Slab',
    fontWeight: 400,
    letterSpacing: '0.5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '36px',
    lineHeight: '36px',
  },
  snackbar: { width: '100%' },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const EditTemplateView = (props) => {

const localizer = momentLocalizer(moment);

  const classes = useStyles();
  const { history } = props;

  //Service Hooks
  const { isAuthenticated, getTokenForApi, appSettings } = useAuth();

  //Redux Hooks
  const dispatch = useDispatch();
  const templateSlots = useSelector((state) => state.reducer.templateSlots);
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);
  const branches = useSelector((state) => state.reducer.branches);

  //Service Hooks
  const templateService = useTemplateService();

  //State Hooks
  const [slotDialog, setSlotDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [customPopUp, setCustomPopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpContent, setPopUpContent] = useState('');
  const [popUpActions, setPopUpActions] = useState('');
  const [templateMode, setTemplateMode] = useState('normal');

  const startOfWeek = moment().utc().startOf('week').toDate();

  //Get the template slots from the API
  useEffect(() => {
    getTokenForApi()
      .then((token) => {
        if (isAuthenticated && token !== '') {
          templateService
            .getTemplateSlots(selectedBranch.id, startOfWeek, token)
            .then((response) => {
              const listOfTemplateEvents = [];
              response.data.map((event) => {
                let e = {
                  id: uuid(),
                  duration: event.duration,
                  quantity: event.quantity,
                  startDateOffset: event.startDateOffset,
                  start: moment(event.startDate).isDST()
                    ? moment(event.startDate)
                        .subtract(1, 'hours')
                        .parseZone()
                        .toDate()
                    : moment(event.startDate).parseZone().toDate(),
                  end: moment(event.startDate).isDST()
                    ? moment(event.startDate)
                        .subtract(1, 'hours')
                        .add(event.duration, 'minutes')
                        .parseZone()
                        .toDate()
                    : moment(event.startDate)
                        .add(event.duration, 'minutes')
                        .parseZone()
                        .toDate(),
                  title: 'Appointment',
                };
                listOfTemplateEvents.push(e);
              });

              //Update events state to contain the most recent list of events
              dispatch(actions.setTemplateSlots(listOfTemplateEvents));
            });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [selectedBranch, isAuthenticated, templateMode]);

  useEffect(() => {
    if (isAuthenticated) {
      if (appSettings.defaultBranch && appSettings.defaultBranchName) {
        //Set selected branch in redux
        dispatch(
          actions.setSelectedBranch({
            name: appSettings.defaultBranchName,
            id: appSettings.defaultBranch,
          })
        );
      } else {
        //Redirect to calendar page
        history.push({
          pathname: '/select-a-branch',
        });
      }
    }
  }, [appSettings, isAuthenticated]);

  // --- Slot Dialog Helpers --- //
  const openSlotDialog = () => setSlotDialog(true);
  const closeSlotDialog = () => {
    setSlotDialog(false);
  };

  //Custom PopUp Helpers
  const openCustomPopUp = () => setCustomPopUp(true);
  const closeCustomPopUp = () => setCustomPopUp(false);
  const closeCustomPopUpWithAction = (
    result,
    resultAction,
    functionParams = []
  ) => {
    resultAction(result, ...functionParams);
    setCustomPopUp(false);
  };

  //Brings up the dialog when add slot button is clicked
  const addSlotButtonClick = () => {
    openSlotDialog();
    setDialogTitle('Add Slot to ' + selectedBranch.name + "'s Template");
    setDialogContent(
      <Fragment>
        <AddTemplateSlot close={closeSlotDialog} type="button" />
      </Fragment>
    );
  };

  const resetCalendarButtonClick = () => {
    openSlotDialog();
    setDialogTitle('Apply Pattern to Template');
    setDialogContent(
      <Fragment>
        <ResetTemplateCalendar close={closeSlotDialog} />
      </Fragment>
    );
  };

  const clearTemplate = (result) => {
    if (result) {
      dispatch(actions.setTemplateSlots([]));
    }
  };

  const clearCalendarButtonClick = () => {
    //Set popup content, title, and actions
    setPopUpTitle(
      <Box className={classes.flex}>
        <WarningRoundedIcon style={{ marginRight: '10px' }} fontSize="large" />
        Warning
      </Box>
    );
    setPopUpContent('Are you sure you would like to clear the template?');
    setPopUpActions(
      <Fragment>
        <Button
          onClick={() => {
            closeCustomPopUpWithAction(true, clearTemplate);
          }}
          variant="contained"
          color="primary"
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            closeCustomPopUp(false);
          }}
          variant="outlined"
          color="primary"
        >
          No
        </Button>
      </Fragment>
    );
    openCustomPopUp();
  };

  const changeBranches = (result, event) => {
    //Only change branches if not in edit mode or if they confirm they want to
    if (result) {
      setTemplateMode('normal');
      //Get Branch Name
      const selectedBranchName = branches.find(
        (branch) => branch.id === event.target.value
      ).name;
      //Set branch in redux
      dispatch(
        actions.setSelectedBranch({
          name: selectedBranchName,
          id: event.target.value,
        })
      );
    }
  };

  //Changes events based on the dropdown
  const handleBranchChange = (event) => {
    if (templateMode === 'edit') {
      //Set popup content, title, and actions
      setPopUpTitle(
        <Box className={classes.flex}>
          <WarningRoundedIcon
            style={{ marginRight: '10px' }}
            fontSize="large"
          />
          Warning
        </Box>
      );
      setPopUpContent(
        <Box>
          <Box>Are you sure you want to change branches?</Box>
          <Box style={{ fontSize: '80%', marginTop: '20px' }}>
            <b>Note</b>: Any unsaved changes will be lost.
          </Box>
        </Box>
      );
      setPopUpActions(
        <Fragment>
          <Button
            onClick={() => {
              closeCustomPopUpWithAction(true, changeBranches, [event]);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              closeCustomPopUpWithAction(false, changeBranches, [event]);
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </Fragment>
      );
      openCustomPopUp();
    } else {
      changeBranches(true, event);
    }
  };

  const leaveEditMode = (result) => {
    if (result) {
      setTemplateMode('normal');
    }
  };

  const leaveEditModeButtonClick = () => {
    setPopUpTitle(
      <Box className={classes.flex}>
        <WarningRoundedIcon style={{ marginRight: '10px' }} fontSize="large" />
        Warning
      </Box>
    );
    setPopUpContent(
      <Box>
        <Box>Are you sure you want to leave Edit Mode?</Box>
        <Box style={{ fontSize: '80%', marginTop: '20px' }}>
          <b>Note</b>: Any changes you have made will NOT be applied to the template.
        </Box>
      </Box>
    );
    setPopUpActions(
      <Fragment>
        <Button
          onClick={() => {
            closeCustomPopUpWithAction(true, leaveEditMode);
          }}
          variant="contained"
          color="primary"
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            closeCustomPopUpWithAction(false, leaveEditMode);
          }}
          variant="outlined"
          color="primary"
        >
          No
        </Button>
      </Fragment>
    );
    openCustomPopUp();
  };

  const saveTemplate = (result) => {
    if (result) {
      //Remove the ghost slots
      const arrayWithoutRemoved = templateSlots.filter((slot) => !slot.removed);
      getTokenForApi()
        .then((token) => {
          if (isAuthenticated && token !== '') {
            //Save template data by calling API - setting template slots to redux state
            templateService
              .updateTemplateSlots(
                selectedBranch.id,
                arrayWithoutRemoved,
                token
              )
              .then((response) => {
                if (response.status === 200) {
                  //What to do if success
                  setTemplateMode('normal');
                }
              });
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const saveTemplateButtonClick = () => {
    //Set popup content, title, and actions
    setPopUpTitle(
      <Box className={classes.flex}>
        <WarningRoundedIcon style={{ marginRight: '10px' }} fontSize="large" />
        Warning
      </Box>
    );
    setPopUpContent(
      <Fragment>
        Are you sure you want to save this template for the
        <span style={{ fontWeight: '600', color: palette.river.main }}>
          {' '}
          {selectedBranch.name}{' '}
        </span>
        branch?
      </Fragment>
    );
    setPopUpActions(
      <Fragment>
        <Button
          onClick={() => {
            closeCustomPopUpWithAction(true, saveTemplate);
          }}
          variant="contained"
          color="primary"
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            closeCustomPopUpWithAction(false, saveTemplate);
          }}
          variant="outlined"
          color="primary"
        >
          No
        </Button>
      </Fragment>
    );
    openCustomPopUp();
  };

  //If data is not saved or in edit mode,
  // pop up a warning if they try to leave the page
  if (templateMode === 'edit') {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = null;
  }

  return (
    <div className={classes.outerContainer}>
      <CustomPopUp
        open={customPopUp}
        title={popUpTitle}
        actions={popUpActions}
        alert
        disableClose
      >
        {popUpContent}
      </CustomPopUp>
      <Prompt
        message='Are you sure you want to leave this page?'
        when={templateMode === 'edit'}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={templateMode === 'edit' ? true : false}
      >
        <SnackbarContent
          style={{ backgroundColor: palette.brick.main }}
          classes={{
            message: classes.snackbar,
          }}
          message={
            <Box
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                You are in <b>Edit Mode</b> for {selectedBranch.name}
              </div>
              <IconButton onClick={leaveEditModeButtonClick}>
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </Box>
          }
        ></SnackbarContent>
      </Snackbar>
      <Box className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Box
              className={clsx(classes.titleBox)}
              style={{
                borderBottom: '1px solid #ededed',
                paddingBottom: '15px',
              }}
            >
              <Box className={classes.leftTitleBox}>
                <Box className={clsx('meetWithNwcu', classes.meetWithNwcu)}>
                  {templateMode === 'edit'
                    ? 'Edit Branch Template'
                    : 'Branch Template'}
                </Box>
                <Box className={classes.instructions}>
                  {templateMode === 'edit' ? (
                    <Box
                      style={{
                        border: '1px solid ' + palette.brick.main,
                        borderRadius: '10px',
                        padding: '10px',
                      }}
                    >
                      In{' '}
                      <span
                        style={{ color: palette.brick.main, fontWeight: 600 }}
                      >
                        Edit Mode
                      </span>
                      , click on an{' '}
                      <span
                        style={{
                          color: palette.mountain.main,
                          fontWeight: 600,
                        }}
                      >
                        existing slot
                      </span>{' '}
                      to change number of appointments for a slot. To create a{' '}
                      <span
                        style={{ color: palette.beach.main, fontWeight: 600 }}
                      >
                        new slot
                      </span>
                      , click and drag on the calendar at the time you want to add a slot. When you release the mouse, a dialog will pop up asking for the number of appointments. You may also manually add a slot by clicking the teal{' '}
                      <span
                        style={{ color: palette.river.main, fontWeight: 600 }}
                      >
                        Add Slot button
                      </span>{' '}
                      on the top left. Once you've added all the slots, click
                      the green{' '}
                      <span
                        style={{ color: palette.forest.main, fontWeight: 600 }}
                      >
                        Save button
                      </span>{' '}
                      in the top right. Appointments with unsaved changes will
                      display as{' '}
                      <span
                        style={{ color: palette.beach.main, fontWeight: 600 }}
                      >
                        tan
                      </span>
                      , so make sure to save!
                    </Box>
                  ) : (
                    <span>
                      {' '}
                      You are currently viewing the template for{' '}<b>{selectedBranch.name}</b>. Click the orange Edit Template button below to add, update, or delete slots from your branch's template.
                    </span>
                  )}
                </Box>
              </Box>
              <MuiTextField
                select
                id="branch"
                name="branch"
                label="Branch"
                variant="outlined"
                style={{ marginRight: '10px', minWidth: '300px' }}
                value={selectedBranch.id}
                onChange={handleBranchChange}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </MuiTextField>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Calendar
                localizer={localizer}
                min={new Date(2020, 1, 1, 9, 0)} //start time at 10am, date doesn't matter
                max={new Date(2020, 1, 1, 17, 59)} //end time at 6pm, date doesn't matter
                views={['week']}
                step={15} // Show 15 minute intervals
                timeslots={4} //Show 4 timeslots per hour
                components={{
                  event: TemplateEvent,
                  week: {
                    header: ({ date, localizer }) => (
                      <TemplateWeekHeader date={date} localizer={localizer} />
                    ),
                  },
                  //   toolbar: (props) => (
                  //     <CalendarToolbar addSlot={addSlotButtonClick} {...props} />
                  //   ),
                  toolbar: (props) => (
                    <TemplateToolbar
                      addSlot={addSlotButtonClick}
                      resetCalendar={resetCalendarButtonClick}
                      clearCalendar={clearCalendarButtonClick}
                      setMode={setTemplateMode}
                      saveTemplateButtonClick={saveTemplateButtonClick}
                      mode={templateMode}
                      {...props}
                    />
                  ),
                }}
                events={templateSlots}
                startAccessor="start"
                endAccessor="end"
                defaultView={'week'}
                drilldownView="null" //Makes headers not clickable.
                style={{ height: 850 }}
                //Special styling for events
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    backgroundColor: palette.mountain.main,
                    color: 'white',
                    border: 'none',
                    border: '1px solid white',
                  };

                  if (event.changed === true) {
                    newStyle.backgroundColor = palette.beach.main;
                  }
                  if (event.removed === true) {
                    newStyle.backgroundColor = palette.beach.dark;
                    newStyle.opacity = '0.4';
                    newStyle.cursor = 'default';
                  }
                  if (templateMode !== 'edit') {
                    newStyle.cursor = 'default';
                  }

                  return {
                    className: '',
                    style: newStyle,
                  };
                }}
                //This is what happens when an event is clicked
                onSelectEvent={(event) => {
                  //Only clickable in edit mode
                  if (templateMode === 'edit') {
                    //Only if it is not a removed event
                    if (!event.removed) {
                      openSlotDialog();
                      setDialogTitle('Edit Template Slot');
                      dispatch(actions.setSelectedEvent(event));
                      setDialogContent(
                        <Fragment>
                          <EditTemplateSlot close={closeSlotDialog} />
                        </Fragment>
                      );
                    }
                  }
                }}
                selectable={templateMode === 'edit' ? true : false}
                //This is what happens when you drag to select an event time
                onSelectSlot={(event) => {
                  openSlotDialog();
                  setDialogTitle(
                    'Add Slot to the Template for' +
                      branches.find((b) => b.id === selectedBranch.id).name +
                      's Calendar'
                     
                  );
                  setDialogContent(
                    <Fragment>
                      <AddTemplateSlot
                        event={event}
                        close={closeSlotDialog}
                        type="drag"
                      />
                    </Fragment>
                  );
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={slotDialog} maxWidth="sm">
        <DialogTitle>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3" color="primary">
              {dialogTitle}
            </Typography>
            <IconButton onClick={closeSlotDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>{dialogContent}</DialogContent>
      </Dialog>
    </div>
  );
};

export default EditTemplateView;

const TemplateEvent = ({ event }) => {
  return (
    <div>
      <div style={{ fontWeight: 500, fontSize: '12px', lineHeight: '15px' }}>
        <span
          style={{
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            letterSpacing: '0.1px',
          }}
        >
          {event.removed === true ? (
            'Removed'
          ) : (
            <div>
              {event.quantity}
              {event.available === 1 ? ' appointment' : ' appointments'}
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

const TemplateWeekHeader = (props) => {
  const classes = useStyles();

  return (
    <div style={{ padding: '10px 0' }}>
      <div className={classes.dayOfWeek}>
        {props.localizer.format(props.date, 'ddd')}
      </div>
      {/* <div className={classes.dateOfWeek}>
        {props.localizer.format(props.date, 'D')}
      </div> */}
    </div>
  );
};

const TemplateToolbar = (props) => {
  //Styles
  const useStyles = makeStyles((theme) => ({
    templateButton: {
      marginBottom: '15px',
      marginRight: '8px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    orangeButton: {
      backgroundColor: palette.brick.main,
      '&:hover': {
        backgroundColor: brick[400],
      },
      '&:focus': {
        backgroundColor: brick[400],
      },
    },
  }));
  const classes = useStyles();

  //Service Hooks
  const { isAuthenticated, getTokenForApi, appSettings } = useAuth();

  const templateService = useTemplateService();

  //Redux Hooks
  const templateSlots = useSelector((state) => state.reducer.templateSlots);
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);

  //Other functions
  const editTemplate = () => {
    props.setMode('edit');
  };

  return (
    <Box className={classes.buttonContainer}>
      {/* Left Buttons */}
      <Box>
        {props.mode === 'edit' ? (
          ''
        ) : (
          <Box>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              className={classes.templateButton}
              classes={{
                root: classes.orangeButton,
              }}
              onClick={() => {
                editTemplate();
              }}
            >
              <EditIcon fontSize="small" style={{ marginRight: '8px' }} />
              Edit Template
            </Button>
          </Box>
        )}

        {props.mode === 'edit' ? (
          <Box>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={props.addSlot}
              className={classes.templateButton}
              style={{ marginRight: '10px' }}
            >
              <AddIcon fontSize="small" style={{ marginRight: '8px' }} /> Add
              Slot
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              className={classes.templateButton}
              onClick={props.resetCalendar}
            >
              <AppsIcon fontSize="small" style={{ marginRight: '8px' }} />
              Apply Pattern
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              className={classes.templateButton}
              onClick={props.clearCalendar}
            >
              <ClearIcon fontSize="small" style={{ marginRight: '8px' }} />
              Clear Template
            </Button>
          </Box>
        ) : (
          ''
        )}
      </Box>
      {/* Right Buttons */}
      <Box>
        {props.mode === 'edit' ? (
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={props.saveTemplateButtonClick}
            className={classes.addSlot}
          >
            <SaveIcon fontSize="small" style={{ marginRight: '8px' }} /> Save
          </Button>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};
