import { BaseClass } from 'utilities';

const ReserveFormModel = class extends BaseClass {
  constructor(props = {}) {
    super(props);
    this.slotId = props.slotId || '';
    this.comment = props.comment || '';
    this.name = props.name || '';
    this.memberNumber = props.memberNumber || '';
    this.phoneNumber = props.phoneNumber || '';
    this.email = props.email || '';
    this.purposeId = props.purposeId || '';
    this.isVideo = props.isVideo || false;
    this.textReminders = props.textReminders || false;
    this.purposeOtherText = props.purposeOtherText || '';
  }
};

export default ReserveFormModel;
