export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export const SET_ALL_BRANCHES = 'SET_ALL_BRANCHES';
export const SET_ALL_PURPOSES = 'SET_ALL_PURPOSES';
export const SET_SELECTED_BRANCH = 'SET_SELECTED_BRANCH';
export const SET_ADD_EVENT_DATA = 'SET_ADD_EVENT_DATA';
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const SET_MODE = 'SET_MODE';
export const SET_RESCHEDULE_INFO = 'SET_RESCHEDULE_INFO';
export const SET_TEMPLATE_SLOTS = 'SET_TEMPLATE_SLOTS';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
