/* eslint-disable react-hooks/exhaustive-deps */
// --- Imports --- //
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// --- Material Ui --- //
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

// // --- Services --- //
// import { useCatalogService } from 'services/CatalogService';

// --- Custom Components --- //
import { Topbar, Footer, TreeFooter } from './components';

// --- Redux --- //
import { connect } from 'react-redux';
// import {
//   actions as CatalogActions,
//   types as CatalogTypes,
// } from 'store/Catalog';

// const mapStateToProps = (state, props) => {
//   return {
//     ...props,
//     catalogStatus: state.catalog.status,
//   };
// };

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     ...props,
//     catalogFetching: () =>
//       dispatch(CatalogActions.setStatus(CatalogTypes.CATALOG_STATUS_FETCHING)),
//     catalogSuccess: () =>
//       dispatch(CatalogActions.setStatus(CatalogTypes.CATALOG_STATUS_SUCCESS)),
//     catalogError: () =>
//       dispatch(CatalogActions.setStatus(CatalogTypes.CATALOG_STATUS_ERROR)),
//     setCatalog: (data) => dispatch(CatalogActions.setCatalog(data)),
//   };
// };

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 88,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 96,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 72,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    //height: '100%',
  },
  footer: {
    color: 'white',
  },
}));

const Main = (props) => {
  const { children } = props;
  const classes = useStyles();
  // const catalogService = useCatalogService();
  // useEffect(() => {
  //   if (props.catalogStatus === CatalogTypes.CATALOG_STATUS_INIT) {
  //     props.catalogFetching();
  //     catalogService
  //       .all()
  //       .then((response) => {
  //         props.setCatalog(response.data);
  //         props.catalogSuccess();
  //       })
  //       .catch(() => props.catalogError());
  //   }
  // }, [props.catalogStatus]);

  // --- KEEP FOR LATER IMPLEMENTATION --- //
  // const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
  //   defaultMatches: true
  // });

  // const [openSidebar, setOpenSidebar] = useState(false);

  // const handleSidebarOpen = () => {
  //   setOpenSidebar(true);
  // };

  // const handleSidebarClose = () => {
  //   setOpenSidebar(false);
  // };

  // const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        // [classes.shiftContent]: isDesktop
      })}
    >
      {/* --- KEEP FOR LATER IMPLEMENTATION --- */}
      {/* <Topbar onSidebarOpen={handleSidebarOpen} /> */}
      {/* <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      /> */}

      <Topbar onSidebarOpen={() => {}} />
      <main className={classes.content}>
        {children}
       
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

// export default connect(mapStateToProps, mapDispatchToProps)(Main);
export default Main;
