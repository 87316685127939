import React, { useState, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import * as yup from 'yup';

//Components
import { Subtitle, CustomPopUp } from 'components';

//Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

//Material UI
import { Box, Button, Grid, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form } from 'formik';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';

//Services
import { useAuth } from 'services/AuthService';

const EditTemplateSlotSchema = yup.object().shape({
  quantity: yup.number().integer().label('Number of Appointments').required(),
});

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    marginBottom: '15px',
  },
  messageBox: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
  },
  successText: {
    color: '#597f54',
  },
  errorText: {
    color: '#e53935',
  },
  chipContainer: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
    borderBottom: '1px solid #cccccc',
    paddingBottom: '15px',
    marginBottom: '25px',
  },
  chip: {
    marginRight: '5px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const EditTemplateSlot = (props) => {
  const classes = useStyles();
  const { close, ...rest } = props;
  const { isAuthenticated, token } = useAuth();

  //Redux Hooks
  const dispatch = useDispatch();
  const selectedEvent = useSelector((state) => state.reducer.selectedEvent);
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);
  const templateSlots = useSelector((state) => state.reducer.templateSlots);

  //State Hooks
  //Yes/No Popups
  const [customPopUp, setCustomPopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpContent, setPopUpContent] = useState('');
  const [popUpActions, setPopUpActions] = useState('');

  //Custom PopUp Helpers
  const openCustomPopUp = () => setCustomPopUp(true);
  const closeCustomPopUp = () => setCustomPopUp(false);
  const closeCustomPopUpWithAction = (
    result,
    resultAction,
    functionParams = []
  ) => {
    resultAction(result, ...functionParams);
    setCustomPopUp(false);
  };

  const deleteSlot = (result) => {
    if (result) {
      //Remove slot from redux
      const idToRemove = selectedEvent.id;

      const removedSlot = {
        ...selectedEvent,
        removed: true,
        title: 'Removed',
      };

      const arrayWithoutSlot = templateSlots.filter(
        (slot) => slot.id !== idToRemove
      );

      dispatch(actions.setTemplateSlots([...arrayWithoutSlot, removedSlot]));
      close();
    }
  };

  const deleteSlotButtonClick = () => {
    if (isAuthenticated && token) {
      setPopUpTitle(
        <Box className={classes.flex}>
          <WarningRoundedIcon
            style={{ marginRight: '10px' }}
            fontSize="large"
          />
          Warning
        </Box>
      );
      setPopUpContent(
        <Box>
          <Box>Are you sure you want to remove this template slot?</Box>
          <Box style={{ fontSize: '80%', marginTop: '20px' }}>
            <b>Note</b>: It will not remove it from the calendar where this
            template has already been applied.
          </Box>
        </Box>
      );
      setPopUpActions(
        <Fragment>
          <Button
            onClick={() => {
              closeCustomPopUpWithAction(true, deleteSlot);
            }}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              closeCustomPopUp(false);
            }}
            variant="outlined"
            color="primary"
          >
            No
          </Button>
        </Fragment>
      );
      openCustomPopUp();
    }
  };

  const updateQuantity = (quantity, resetForm) => {
    const updatedSlot = {
      ...selectedEvent,
      quantity: parseInt(quantity),
      changed: true,
    };
    //Updating redux state for displaying the correct number of appointments
    dispatch(actions.setSelectedEvent(updatedSlot));

    //Remove current slot
    const arrayWithoutSlot = templateSlots.filter(
      (slot) => slot.id !== selectedEvent.id
    );

    //Update whole list so it displays correctly and add in the updated slot
    dispatch(actions.setTemplateSlots([...arrayWithoutSlot, updatedSlot]));

    resetForm({ values: { quantity: quantity } });
    close();
  };

  return (
    <div className={classes.box}>
      <CustomPopUp
        open={customPopUp}
        title={popUpTitle}
        actions={popUpActions}
        alert
        disableClose
      >
        {popUpContent}
      </CustomPopUp>
      <Formik
        initialValues={{ quantity: selectedEvent.quantity }}
        validationSchema={EditTemplateSlotSchema}
        onSubmit={(values, { resetForm }) => {
          // console.log('Submitted Values', values);
          updateQuantity(values.quantity, resetForm);
        }}
      >
        <Form>
          <Fragment>
            <Box className={classes.chipContainer}>
              <Chip
                label={moment(selectedEvent.start).format('dddd')}
                size="small"
              />
              <Chip
                label={moment(selectedEvent.start).format('hh:mm A')}
                size="small"
              />
              <Chip
                label={
                  moment(selectedEvent.end).diff(
                    selectedEvent.start,
                    'minutes'
                  ) + ' minutes'
                }
                size="small"
              />
              <Chip label={selectedBranch.name + ' Branch'} size="small" />
            </Box>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                {' '}
                <TextField
                  name="quantity"
                  label="Number of Appointments"
                  variant="outlined"
                  fullWidth
                  type="number"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ marginRight: '15px' }}
                >
                  Update
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={deleteSlotButtonClick}
                >
                  Remove Slot From Template
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        </Form>
      </Formik>
    </div>
  );
};

export default EditTemplateSlot;
