import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import * as yup from 'yup';

//Components
import { Subtitle } from 'components';

//Material UI
import {
  Box,
  IconButton,
  Button,
  TextField as MuiTextField,
  MenuItem,
  InputAdornment,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form } from 'formik';

//Schema

//Services
import { useAuth } from 'services/AuthService';
import { useTemplateService } from 'services/TemplateService';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    marginBottom: '15px',
  },
  note: {
    fontStyle: 'italic',
    fontWeight: 300,
    lineHeight: 1.3,
    letterSpacing: '0.1px',
  },
}));

//Schema
const ResetTemplateCalendarSchema = yup.object().shape({
  duration: yup.number().integer().label('Duration').required(),
  startTime: yup.string().label('Start Time').required(),
  endTime: yup.string().label('End Time').required(),
  gap: yup.number().integer().label('Gap').required(),
  quantity: yup.number().integer().label('Number of Appointments').required(),
});

const ResetTemplateCalendar = (props) => {
  const classes = useStyles();
  const { isAuthenticated, token } = useAuth();
  const { close, ...rest } = props;

  //State Hooks
  const [resetChecked, setResetChecked] = useState(false);

  //Redux Hooks
  const dispatch = useDispatch();
  const templateSlots = useSelector((state) => state.reducer.templateSlots);

  const handleResetChecked = (event) => {
    setResetChecked(event.target.checked);
  };

  const submitResetCalendar = (values) => {
    const hourStart = parseInt(values.startTime.substring(0, 2));
    const minuteStart = parseInt(values.startTime.substring(3, 5));
    const hourEnd = parseInt(values.endTime.substring(0, 2));
    const minuteEnd = parseInt(values.endTime.substring(3, 5));
    const duration = parseInt(values.duration);

    const sunday = moment().startOf('week');
    const beginningOfTime = moment()
      .year(2000)
      .dayOfYear(1)
      .hour(0)
      .minute(0)
      .seconds(0)
      .milliseconds(0);

    const newTemplateSlots = [];
    let startDateOffset = moment();
    let start = moment();
    let end = moment();
    let currentDay = 1;
    let startTime = sunday
      .clone()
      .add(hourStart, 'hours')
      .add(minuteStart, 'minutes');
    let endTime = sunday
      .clone()
      .add(hourEnd, 'hours')
      .add(minuteEnd, 'minutes');

    while (currentDay < 6) {
      let currentTime = startTime.clone().add(currentDay, 'days');
      let currentEndTime = endTime.clone().add(currentDay, 'days');
      while (currentTime < currentEndTime) {
        //Calculate startDateOffset, start, and end
        startDateOffset = beginningOfTime
          .clone()
          .add(currentDay, 'days')
          .add(currentTime.hours(), 'hours')
          .add(currentTime.minutes(), 'minutes');

        start = sunday
          .clone()
          .add(currentDay, 'days')
          .add(currentTime.hours(), 'hours')
          .add(currentTime.minutes(), 'minutes');
        end = start.clone().add(duration, 'minutes');

        const newSlot = {
          //These change
          startDateOffset: startDateOffset.toDate(),
          start: start.toDate(),
          end: end.toDate(),

          //These stay the same
          duration: values.duration,
          quantity: parseInt(values.quantity),
          id: uuid(),
          title: 'Appointment',
          changed: true,
        };

        newTemplateSlots.push(newSlot);
        //Move currentTime forward for the next slot
        currentTime.add(values.duration + values.gap, 'minutes');
      }
      currentDay++;
    }

    if (resetChecked === true) {
      dispatch(actions.setTemplateSlots(newTemplateSlots));
    } else {
      dispatch(
        actions.setTemplateSlots([...templateSlots, ...newTemplateSlots])
      );
    }
    close();
  };

  return (
    <div className={classes.box}>
      <Formik
        initialValues={{
          duration: '',
          quantity: '',
          startTime: '',
          endTime: '',
          gap: '',
        }}
        validationSchema={ResetTemplateCalendarSchema}
        enableReinitialize
        onSubmit={(values) => {
          // console.log('Submitted values', values);
          submitResetCalendar(values);
        }}
      >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography className={classes.note}>
                This tool will create a pattern of template slots based on your entries below. If you check the checkbox, all existing slots will be removed and will be replaced by the pattern of
                appointments created by this tool. If you don't check the box, the slots will be added to whatever is currently on the template.
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                name="duration"
                label="Duration of Appointments"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">minutes</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                name="quantity"
                label="Number of Appointments"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                name="startTime"
                label="Start Time"
                variant="outlined"
                fullWidth
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: '06:00',
                  max: '21:00',
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                name="endTime"
                label="End Time for Slots"
                variant="outlined"
                fullWidth
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: '06:00',
                  max: '21:00',
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                name="gap"
                label="Gap Between Each Slot"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">minutes</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={resetChecked}
                    onChange={handleResetChecked}
                    name="reset"
                  />
                }
                label="Clear existing slots?"
              />
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" variant="contained" type="submit">
                Apply Pattern
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
};
export default ResetTemplateCalendar;
