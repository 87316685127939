import React, { useState, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import * as yup from 'yup';

//Icons
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import VideocamIcon from '@material-ui/icons/Videocam';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ChatIcon from '@material-ui/icons/Chat';

//Material UI
import {
  Box,
  IconButton,
  Button,
  ButtonBase,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import palette from 'themes/palette';

//Components
import { Subtitle, CustomPopUp, CustomPopUpAlert } from 'components';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';

//Services
import { useReservationService } from 'services/ReservationService';
import { useSlotService } from 'services/SlotService';

import { useTranslation, Trans } from 'react-i18next';

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
  },
  title: {
    color: palette.highway.main,
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5px',
      marginBottom: '15px',
    },
  },
  gridContainer: {
    backgroundColor: 'white',
    padding: '20px 30px',
    border: '1px solid #dddddd',
    borderRadius: '15px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '3px solid ' + palette.forest.main,
    padding: '10px 20px',
    '&:not(:first-child)': {
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px',
      },
    },
  },

  attendeeInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
    '&:not(:first-child)': {
      marginTop: '5px',
    },
  },
  icon: {
    marginRight: '10px',
    color: palette.highway.main,
  },
  attendeeName: {
    fontWeight: '500',
    color: palette.forest.dark,
    fontSize: '120%',
  },
  attendeeDetails: {
    fontWeight: 300,
  },
  attendeeButtons: {
    marginTop: '15px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      flexDirection: 'column',
    },
  },
  topButton: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px',
    },
  },

  messageBox: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
  },
  successText: {
    color: palette.forest.main,
  },
  link: {
    color: 'white',
    fontWeight: 600,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function maskPhoneNumber(string) {
  return (
    '(' +
    string.slice(0, 3) +
    ') ' +
    string.slice(3, 6) +
    '-' +
    string.slice(6, 10)
  );
}

const CancelView = (props) => {
  const classes = useStyles();
  const { match, history } = props;

  //State Hooks
  const [status, setStatus] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [reservation, setReservation] = useState({});
  const [slotInfo, setSlotInfo] = useState({});
  const [cancelled, setCancelled] = useState(false);
  const [branchesandpurposesloaded, setbranchesandpurposesloaded] = useState(
    false
  );
  //Yes/no popup
  const [customPopUp, setCustomPopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpContent, setPopUpContent] = useState('');
  const [popUpActions, setPopUpActions] = useState('');
  //Alert popup
  const [customPopUpAlert, setCustomPopUpAlert] = useState(false);
  const [popUpAlertTitle, setPopUpAlertTitle] = useState('');
  const [popUpAlertContent, setPopUpAlertContent] = useState('');
  const [popUpAlertType, setPopUpAlertType] = useState('neutral');

  //Redux Hooks
  const dispatch = useDispatch();
  const purposes = useSelector((state) => state.reducer.purposes);
  const branches = useSelector((state) => state.reducer.branches);

  //Service Hooks
  const reservationService = useReservationService();
  const slotService = useSlotService();

  //Custom PopUp Helpers
  const openCustomPopUp = () => setCustomPopUp(true);
  const closeCustomPopUp = () => setCustomPopUp(false);
  const closeCustomPopUpWithAction = (
    result,
    resultAction,
    functionParams = []
  ) => {
    resultAction(result, ...functionParams);
    setCustomPopUp(false);
  };

  //Custom PopUp Helpers
  const openCustomPopUpAlert = () => setCustomPopUpAlert(true);
  const closeCustomPopUpAlert = () => setCustomPopUpAlert(false);

  //Get the ID from the URL
  const reservationId = match.params.id;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (purposes && purposes.length > 0 && branches && branches.length > 0) {
      setbranchesandpurposesloaded(true);
    }
  }, [purposes, branches]);
  //Need to get this from the URL
  // const reservationId = '3da2c78a-640e-4289-bb76-64116571abcb';
  useEffect(() => {
    if (branchesandpurposesloaded) {
      reservationService
        .getReservationDetails(reservationId)
        .then((response) => {
          if (response.status === 200) {
            setStatus(response.status);
            setReservation(response.data);
            setIsLoaded(true);
            slotService
              .getSlotDetails(response.data.slotId)
              .then((slotResponse) => {
                setSlotInfo(slotResponse.data);
                //Set selected branch in redux in case they choose to reschedule
                dispatch(
                  actions.setSelectedBranch({
                    name: branches.find((b) => b.id === slotInfo.branchId)
                      ? branches.find((b) => b.id === slotInfo.branchId).name
                      : '',
                    id: slotResponse.data.branchId,
                  })
                );
              });
            if (response.data.cancelledAt !== null) {
              setCancelled(true);
            }
          } else {
            setStatus(response.status);
            setIsLoaded(true);
          }
        });
    }
  }, [branchesandpurposesloaded]);

  const cancelAppointment = (result, id) => {
    if (result) {
      reservationService.cancelReservation(id, true).then((response) => {
        if (response.status === 200) {
          setPopUpAlertTitle(i18n.t('success'));
          setPopUpAlertContent(i18n.t('yourAppointmentHasBeenCancelled'));
          setPopUpAlertType('success');
          openCustomPopUpAlert();
          setCancelled(true);
        } else {
          setPopUpAlertTitle(i18n.t('error'));
          setPopUpAlertContent(
            i18n.t('somethingWentWrong')
          );
          setPopUpAlertType('error');
          openCustomPopUpAlert();
        }
      });
    }
  };

  const cancelAppointmentButtonClick = (id) => {
    setPopUpTitle(
      <Box className={classes.flex}>
        <WarningRoundedIcon style={{ marginRight: '10px' }} fontSize="large" />
        Warning
      </Box>
    );
    setPopUpContent(i18n.t('areYouSureYouWantToCancelThisAppointment'));
    setPopUpActions(
      <Fragment>
        <Button
          onClick={() => {
            closeCustomPopUpWithAction(true, cancelAppointment, [id]);
          }}
          variant="contained"
          color="primary"
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            closeCustomPopUp(false);
          }}
          variant="outlined"
          color="primary"
        >
          No
        </Button>
      </Fragment>
    );
    openCustomPopUp();
  };

  const rescheduleAppointment = (id) => {
    //Set redux reschedule info
    dispatch(actions.setRescheduleInfo(reservation));
    //Set redux mode to reschedule
    dispatch(actions.setMode('reschedule'));
    //Redirect to customer calendar view in reschedule mode
    history.push({
      pathname: '/calendar',
    });
  };

  return (
    <Box className={classes.outerContainer}>
      <CustomPopUpAlert
        open={customPopUpAlert}
        title={popUpAlertTitle}
        type={popUpAlertType}
        onClose={closeCustomPopUpAlert}
        disableClose
      >
        {popUpAlertContent}
      </CustomPopUpAlert>
      <CustomPopUp
        open={customPopUp}
        title={popUpTitle}
        actions={popUpActions}
        alert
        disableClose
      >
        {popUpContent}
      </CustomPopUp>
      <Box className={clsx('pageTitle', classes.title)}>
        {t('appointmentDetails')}
      </Box>
      {isLoaded && branchesandpurposesloaded ? (
        status === 200 ? (
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} md={6} className={classes.section}>
              <Subtitle>{t('appointmentInfo')}</Subtitle>
              {/* This info needs to come from an API call */}
              <Box className={classes.attendeeInfo}>
                <Box>
                  {slotInfo !== {} ? (
                    <Box>
                      <Box
                        className={clsx(classes.item, classes.attendeeDetails)}
                      >
                        <CalendarTodayIcon
                          className={classes.icon}
                          fontSize="small"
                        />
                        {moment(slotInfo.startDate)
                          .add(
                            moment(slotInfo.startDate)
                              .tz('America/Los_Angeles')
                              .utcOffset(),
                            'minutes'
                          )
                          .format('dddd, MMMM D, YYYY')}
                      </Box>
                      <Box
                        className={clsx(classes.item, classes.attendeeDetails)}
                      >
                        <ScheduleIcon
                          className={classes.icon}
                          fontSize="small"
                        />
                        {moment(slotInfo.startDate)
                          .add(
                            moment(slotInfo.startDate)
                              .tz('America/Los_Angeles')
                              .utcOffset(),
                            'minutes'
                          )
                          .format('hh:mm A') +
                          ' - ' +
                          moment(slotInfo.startDate)
                            .add(slotInfo.duration, 'minutes')
                            .add(
                              moment(slotInfo.startDate)
                                .tz('America/Los_Angeles')
                                .utcOffset(),
                              'minutes'
                            )
                            .format('hh:mm A')}
                      </Box>
                      <Box
                        className={clsx(classes.item, classes.attendeeDetails)}
                      >
                        <LocationOnIcon
                          className={classes.icon}
                          fontSize="small"
                        />
                        {branches.find((b) => b.id === slotInfo.branchId)
                          ? branches.find((b) => b.id === slotInfo.branchId)
                              .name
                          : ''}
                      </Box>
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              <Box style={{ flexGrow: 1 }} />
              <Box>
                {cancelled === false ? (
                  <Box className={classes.attendeeButtons}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      style={{ marginRight: '10px' }}
                      onClick={() =>
                        cancelAppointmentButtonClick(reservation.id)
                      }
                      className={classes.topButton}
                    >
                      {t('cancelReservation')}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      style={{ marginRight: '10px' }}
                      onClick={rescheduleAppointment}
                    >
                      {t('rescheduleReservation')}
                    </Button>
                  </Box>
                ) : (
                  <Box
                    style={{
                      marginTop: '20px',
                      fontStyle: 'italic',
                      fontWeight: 300,
                      backgroundColor: palette.brick.main,
                      padding: '10px',
                      color: 'white',
                      borderRadius: '8px',
                    }}
                  >
                    {t('thisAppointmentHasBeenCancelled')}{' '}
                    <a href={'/calendar'} className={classes.link}>
                      {t('clickHere')}
                    </a>{' '}
                    {t('toScheduleANewOne')}
                  </Box>
                )}
              </Box>
            </Grid>
            {reservation.phoneNumber ? (
              <Grid item xs={12} md={6} className={classes.section}>
                <Subtitle>{t('yourInfo')}</Subtitle>

                <Box className={classes.attendeeInfo}>
                  <Box>
                    <Box className={clsx(classes.item, classes.attendeeName)}>
                      <PersonIcon className={classes.icon} fontSize="small" />
                      {reservation.name}
                    </Box>
                    <Box
                      className={clsx(classes.item, classes.attendeeDetails)}
                    >
                      <EmailIcon className={classes.icon} fontSize="small" />
                      {reservation.email}
                    </Box>
                    <Box
                      className={clsx(classes.item, classes.attendeeDetails)}
                    >
                      <PhoneIcon className={classes.icon} fontSize="small" />
                      {maskPhoneNumber(reservation.phoneNumber)}
                      {/* {reservation.phoneNumber} */}
                    </Box>
                    <Box
                      className={clsx(classes.item, classes.attendeeDetails)}
                    >
                      <ChatBubbleIcon
                        className={classes.icon}
                        fontSize="small"
                      />
                      {
                        purposes.find(
                          (purpose) => purpose.id === reservation.purposeId
                        ).title
                      }
                    </Box>
                    {/* <Box
                      className={clsx(classes.item, classes.attendeeDetails)}
                    >
                      <ChatIcon className={classes.icon} fontSize="small" />
                      {reservation.comment !== ''
                        ? reservation.comment
                        : 'No Comment provided'}
                    </Box> */}
                    <Box
                      className={clsx(classes.item, classes.attendeeDetails)}
                    >
                      <i
                        className={clsx('fas fa-hashtag', classes.icon)}
                        style={{
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      ></i>
                      {reservation.memberNumber !== ''
                        ? reservation.memberNumber
                        : i18n.t('noMemberNumberProvided')}
                    </Box>
                    {reservation.isVideo ? (
                      <Box
                        className={clsx(classes.item, classes.attendeeDetails)}
                      >
                        <VideocamIcon
                          className={classes.icon}
                          fontSize="small"
                        />
                        {t('videoAppointment')}
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        ) : (
          <Box
            style={{
              backgroundColor: 'white',
              border: '1px solid #dddddd',
              borderRadius: '15px',
              padding: '20px',
              color: palette.highway.main,
              fontWeight: 600,
            }}
          >
            i18n.t('couldNotFindReservation')
          </Box>
        )
      ) : (
        //Show spinner
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <CircularProgress
            style={{ marginBottom: '20px' }}
            color="secondary"
          />
          {t('loading')}
        </Box>
      )}
    </Box>
  );
};

export default CancelView;
