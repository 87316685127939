/* eslint-disable no-unused-vars */
// --- Imports --- //
import axios from 'axios';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// -- Services --- //
// import LogService, { useLogService } from 'services/LogService';

const SlotService = class {
  // constructor(logService, recaptchaService) {
  constructor() {
    // Axios client initialization
    this.client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_SLOT_API_VERSION}/slot`,
      validateStatus: function () {
        return true;
      },
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_API_SUB_KEY}` }
    });
    // ReCaptcha Service
    // this.recaptchaService = recaptchaService || function () { };
    //Log Service
    // this.logService = logService || null;
  }

  //Get all slots given a branch, start date, and end date
  async get(branchId, startDate, endDate) {
    // console.log('branch', branchId, startDate, endDate);
    // const token = await this.recaptchaService();
    // this.logService.create('Application Retrieving');

    try {
      const { data: response, status } = await this.client.get(
        '/get',
        {
          params: {
            branchId: branchId,
            startDate: startDate,
            endDate: endDate,
            locale: moment.locale()
          },
        }
        // {
        //   headers: { token: token },
        // }
      );

      // retrieve application sessionId
      // const sessionId = (JSON.parse(response.data) || {}).sessionId;
      // this.logService.create(
      //     sessionId,
      //     'Application Retrieved',
      //     'Applicant',
      //     response.data
      // );

      // console.log('RESPONSE', response);
      return response;
    } catch (error) {
      // console.log('error', error);
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }

  //Get slot details given a slotId
  async getSlotDetails(slotId) {
    // const token = await this.recaptchaService();
    // this.logService.create('Application Retrieving');
    try {
      const { data: response, status } = await this.client.get('/details', {
        params: {
          slotId: slotId,
          includeCancelled: false,
        },
      });

      // console.log('RESPONSE', response);
      return response;
    } catch (error) {
      // console.log('error', error);
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }

  async reserveByEmployee(info, token) {
    try {
      const { data: response, status } = await this.client.post(
        '/reserve',
        info,

        {
          params: { sendNotification: true, locale: moment.locale() },
          headers: { Authorization: 'bearer ' + token },
        }
      );

      // console.log('RESPONSE', response);
      return response;
    } catch (error) {
      // console.log('error', error);
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }

  //Customer reserve
  async reserve(info) {
    try {
      const { data: response, status } = await this.client.post(
        '/reserve',
        info,

        {
          params: { 
            sendNotification: true, locale: moment.locale()
          },
        }
      );

      // console.log('RESPONSE', response);
      return response;
    } catch (error) {
      // console.log('error', error);
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }

  async createNewSlot(slotInfo, token) {
    // console.log(slotInfo);
    try {
      const { data: response, status } = await this.client.post(
        '/create',
        slotInfo,
        {
          headers: { Authorization: 'bearer ' + token },
        }
      );

      // console.log('RESPONSE', response);
      return response;
    } catch (error) {
      // console.log('error', error);
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }

  async updateQuantity(slotId, quantity, token) {
    try {
      const { data: response, status } = await this.client.post(
        '/update',
        {},
        {
          params: {
            slotId: slotId,
            quantity: quantity,
          },
          headers: { Authorization: 'bearer ' + token },
        }
        // {
        //   headers: { token: token },
        // }
      );

      // console.log('RESPONSE', response);
      return response;
    } catch (error) {
      // console.log('error', error);
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }

  async deleteSlot(slotId, token) {
    try {
      const { data: response, status } = await this.client.post(
        '/delete',
        {},
        {
          params: {
            slotId: slotId,
          },
          headers: { Authorization: 'bearer ' + token },
        }
        // {
        //   headers: { token: token },
        // }
      );

      // console.log('RESPONSE', response);
      return response;
    } catch (error) {
      // console.log('error', error);
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }
};

export const useSlotService = (props) => {
  //const { executeRecaptcha } = useGoogleReCaptcha();
  //const logService = useLogService('SlotService');

  // return new SlotService(logService, executeRecaptcha);
  return new SlotService();
};

// export default new SlotService(LogService('SlotService'));
export default new SlotService();
