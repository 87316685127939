// --- Yup/Validation Imports --- //
import * as yup from 'yup';
import { phone } from 'utilities/validation/regex';

const ReserveFormSchema = yup.object().shape({
  name: yup.string().label('Name').required(),
  email: yup.string().label('Email').email()
  //Should be required for customers, not required for admin.
  .required(),
  phoneNumber: yup
    .string()
    .label('Phone')
    .matches(phone.regex, phone.message)
    .required(),
  purposeId: yup.string().label('Appointment Reason').required(),
});

export default ReserveFormSchema;
