// --- Imports --- //
import React from 'react';

// --- Material Ui Imports --- //
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import palette from 'themes/palette';

const useStyles = makeStyles(theme => ({
  root: {
    color: palette.forest.dark,
    fontWeight: 500
  }
}));

function Title(props) {
  const { children, ...rest } = props;
  const classes = useStyles();
  return (
    <Box {...rest}>
      <Typography variant="h2" className={classes.root} gutterBottom>
        {children}
      </Typography>
    </Box>
  );
}

export default Title;
