/* eslint-disable no-unused-vars */
// --- Imports --- //
import axios from 'axios';
import moment from 'moment';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// -- Services --- //
// import LogService, { useLogService } from 'services/LogService';

const ReservationService = class {
  // constructor(logService, recaptchaService) {
  constructor() {
    // Axios client initialization
    this.client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_SLOT_API_VERSION}/reservation`,
      validateStatus: function () {
        return true;
      },
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_API_SUB_KEY}` }
    });
    // ReCaptcha Service
    // this.recaptchaService = recaptchaService || function () { };
    //Log Service
    // this.logService = logService || null;
  }

  async cancelReservation(reservationId, sendNotification) {
    
    try {
      const { data: response, status } = await this.client.post(
        '/cancel',
        {},
        {
          params: {
            reservationId: reservationId,
            sendNotification: sendNotification,
            locale: moment.locale()
          },
        }
      );
      return response;
    } catch (error) {
      // console.log('error', error);
      return {};
    }
  }

  async cancelReservationByEmployee(reservationId, sendNotification, token) {
    try {
      const { data: response, status } = await this.client.post(
        '/cancel',
        {},
        {
          params: {
            reservationId: reservationId,
            sendNotification: sendNotification,
            locale: moment.locale()
          },
          headers: { Authorization: 'bearer ' + token },
        }
      );
      return response;
    } catch (error) {
      // console.log('error', error);
      return {};
    }
  }

  //Get all slots given a branch, start date, and end date
  async getReservationDetails(reservationId) {
    try {
      const { data: response, status } = await this.client.get('/details', {
        params: {
          reservationId: reservationId,
        },
      });

      // console.log('RESPONSE', response);
      return response;
    } catch (error) {
      // console.log('error', error);
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }
};

export const useReservationService = (props) => {
  //const { executeRecaptcha } = useGoogleReCaptcha();
  //const logService = useLogService('ReservationService');

  // return new ReservationService(logService, executeRecaptcha);
  return new ReservationService();
};

// export default new ReservationService(LogService('ReservationService'));
export default new ReservationService();
