// --- Imports --- //
import React from 'react';
import palette from 'themes/palette';
import clsx from 'clsx';

// --- Material Ui Imports --- //
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: palette.river.main,
    paddingBottom: '15px',
  },
}));

function Subtitle(props) {
  const classes = useStyles();
  const { className, ...rest } = props;

  return (
    <Typography
      className={clsx(classes.root, className)}
      {...rest}
      variant="h3"
    >
      {props.children}
    </Typography>
  );
}

export default Subtitle;
