import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, InputAdornment } from '@material-ui/core';

import { useField } from 'formik';
import { phoneNumber } from './Masks';

const TextMaskCustom = (props) => {
  const { name, helperText, maskType, ...rest } = props;

  // -- Hooks ---//
  const [field, meta, helpers] = useField(name);
  const [displayValue, setDisplayValue] = useState(maskType);
  const [mask, setMask] = useState(maskType === 'phone' ? phoneNumber : []);

  const showError = meta.error && meta.touched;

  useEffect(() => {
    setDisplayValue(field.value);
  },[field.value])

  // -- handles change events -- //
  const handleChange = (event) => {
    const newValue = event.target.value;
    helpers.setValue(unmaskValue(newValue));
    setDisplayValue(newValue);
  };

  // This makes it so it doesnt show an error until the box has been left
  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <TextField
      error={showError}
      value={displayValue}
      helperText={showError ? meta.error : helperText}
      onChange={handleChange}
      onBlur={(event) => {
        handleChange(event);
        handleBlur();
      }}
      InputProps={{
        inputComponent: CustomMaskInput,
        inputProps: {
          mask: mask,
        },
      }}
      {...rest}
    />
  );
};

//  Unmask value given masking char
//  @param {string} value
//  @param {Regex} mask
//  @param {char} replace

export const unmaskValue = (value, mask = /\D/g, replace = '') => {
  if (typeof value !== 'string') {
    value = value.toString();
  }
  return value.replace(mask, replace);
};

// Custom Mask Input wrapper component
//@param {obj} props Values to passed to MaskedInput component

const CustomMaskInput = (props) => {
  const { inputRef, ...rest } = props;

  return (
    <MaskedInput
      ref={(ref) => (ref ? inputRef(ref.inputElement) : null)}
      {...rest}
    />
  );
};

export default TextMaskCustom;
