import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  TextField as MuiTextField,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { TextField } from 'formik-material-ui';
import { Formik, Form } from 'formik';

import CloseIcon from '@material-ui/icons/Close';

import { useTranslation, Trans } from 'react-i18next';

const localizer = momentLocalizer(moment);

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 8px',
    },
  },
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
    marginBottom: '15px',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
}));

// get branches from API
const branches = [
  {
    value: '1',
    label: 'Gateway',
  },
  {
    value: '2',
    label: 'Downtown Eugene',
  },
  {
    value: '3',
    label: 'Roseburg',
  },
  {
    value: '4',
    label: 'Medford',
  },
];

//Get events for 4 weeks from API
const myEventsList = [
  {
    id: 0,
    title: 'Appointment',
    allDay: false,
    start: new Date(2020, 3, 6, 12, 0, 0),
    end: new Date(2020, 3, 6, 13, 0, 0),
    slots: 4,
    open: 2,
    members: ['Jim', 'Pam'],
  },
  {
    id: 1,
    title: 'Appointment',
    allDay: false,
    start: new Date(2020, 3, 7, 13, 0, 0),
    end: new Date(2020, 3, 7, 13, 30, 0),
    slots: 3,
    open: 0,
    members: ['Michael', 'Dwight', 'Angela'],
  },
  {
    id: 3,
    title: 'Appointment',
    allDay: false,
    start: new Date(2020, 3, 7, 11, 0, 0),
    end: new Date(2020, 3, 7, 12, 0, 0),
    slots: 3,
    open: 1,
    members: ['Andy', 'Karen'],
  },
  {
    id: 2,
    title: 'Appointment',
    allDay: false,
    start: new Date(2020, 3, 9, 12, 0, 0),
    end: new Date(2020, 3, 9, 12, 30, 0),
    slots: 5,
    open: 2,
    members: ['Kelly', 'Ryan', 'Toby'],
  },
];

//Get events for 4 weeks from API
const myEventsList2 = [
  {
    id: 0,
    title: 'Appointment',
    allDay: false,
    start: new Date(2020, 3, 6, 14, 0, 0),
    end: new Date(2020, 3, 6, 15, 0, 0),
    slots: 4,
    open: 2,
    members: ['Kailyn', 'Henry'],
  },
];


const TestEvent = ({ event }) => (
  
  <div>
    <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
      {/* {event.open + '/' + event.slots + ' '} -{' '} */}
      <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
        {event.open === 0 ? "Unavailable" : "Available"}
      </span>
    </div>
  </div>
);

const MyCalendar = (props) => {
  const classes = useStyles();
  const [appointmentDialog, setAppointmentDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [appointments, setAppointments] = useState(myEventsList);
  const [branch, setBranch] = React.useState('1');

  const { t, i18n } = useTranslation();

  // --- Document Dialog Helpers --- //
  const openAppointmentDialog = () => setAppointmentDialog(true);
  const closeAppointmentDialog = () => setAppointmentDialog(false);

  //   useEffect(() => {
  //     console.log('Name in the useEffect', name);
  //   }, [name]);

  const joinAppointment = (id, openSlots, personName) => {
    console.log('Name: ', personName);
    const index = appointments.findIndex((x) => x.id === id);
    console.log('index', index);

    appointments[index] = {
      ...appointments[index],
      open: openSlots - 1,
      members: [...appointments[index].members, personName],
    };

    console.log('MY EVENTS: ', appointments);
  };

  const handleChange = (event) => {
    setBranch(event.target.value);
    if (event.target.value === '2') {
      setAppointments(myEventsList2);
    } else setAppointments(myEventsList);
  };

  return (
    <div className={classes.outerContainer}>
      <Box className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Box
              className={clsx(classes.titleBox)}
              style={{
                borderBottom: '1px solid #ededed',
                paddingBottom: '15px',
              }}
            >
              <Typography variant="h2" className={classes.title}>
                {t('meet')}
              </Typography>
              <MuiTextField
                select
                id="branch"
                name="branch"
                label="Select a branch"
                variant="outlined"
                style={{ marginRight: '10px', minWidth: '300px' }}
                value={branch}
                onChange={handleChange}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.value} value={branch.value}>
                    {branch.label}
                  </MenuItem>
                ))}
              </MuiTextField>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Calendar
                localizer={localizer}
                min={new Date(2020, 1, 1, 10)} //start time at 8am, date doesn't matter
                max={new Date(2020, 1, 1, 17)} //end time at 5pm, date doesn't matter
                views={['month', 'week', 'day']}
                step={15} // Show 15 minute intervals
                timeslots={4} //Show 4 timeslots per hour
                components={{ event: TestEvent }}
                events={appointments}
                startAccessor="start"
                endAccessor="end"
                defaultView="week"
                style={{ height: 700 }}
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    backgroundColor: '#8ac484',
                    color: 'black',
                    border: 'none',
                  };

                  if (event.open === 0) {
                    newStyle.backgroundColor = '#cccccc';
                  }

                  return {
                    className: '',
                    style: newStyle,
                  };
                }}
                onSelectEvent={(event) => {
                  console.log('Event when you click on it: ', event);
                  openAppointmentDialog();
                  setDialogContent(
                    <Fragment>
                      <Formik
                        initialValues={{ name: '' }}
                        onSubmit={(values) => {
                          console.log('Submitted Values', values);
                          joinAppointment(event.id, event.open, values.name);
                          closeAppointmentDialog();
                        }}
                      >
                        <Form>
                          <Fragment>
                            <Box style={{ marginBottom: '15px' }}>
                              {'There are ' +
                                event.open +
                                ' slots open out of ' +
                                event.slots +
                                ' total slots'}
                            </Box>
                            <b>Current Attendees: </b>
                            {event.members.map((member, index) => {
                              return <Box key={index}>{member}</Box>;
                            })}

                            <Box
                              style={{
                                borderTop: '1px solid #cccccc',
                                marginTop: '10px',
                              }}
                            >
                              <Box>
                                {event.open === 0 ? (
                                  <Box
                                    style={{
                                      marginTop: '10px',
                                      color: 'red',
                                    }}
                                  >
                                    This event is full. Please choose a
                                    different time
                                  </Box>
                                ) : (
                                  <Box
                                    style={{
                                      marginTop: '20px',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item sm={6} xs={12}>
                                        <TextField
                                          id="name"
                                          name="name"
                                          label="Name"
                                          variant="outlined"
                                          style={{ marginRight: '10px' }}
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item sm={6} xs={12}>
                                        {' '}
                                        <MuiTextField
                                          // id="name"
                                          // name="name"
                                          label="Member ID (optional)"
                                          variant="outlined"
                                          style={{ marginRight: '10px' }}
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item sm={6} xs={12}>
                                        {' '}
                                        <MuiTextField
                                          // id="name"
                                          // name="name"
                                          label="Email"
                                          variant="outlined"
                                          style={{ marginRight: '10px' }}
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item sm={6} xs={12}>
                                        {' '}
                                        <MuiTextField
                                          // id="name"
                                          // name="name"
                                          label="Phone number"
                                          variant="outlined"
                                          style={{ marginRight: '10px' }}
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Button
                                          disabled={
                                            event.open === 0 ? true : false
                                          }
                                          color="primary"
                                          variant="contained"
                                          type="submit"
                                          // onClick={() => {
                                          //   joinAppointment(event.id, event.open);
                                          // }}
                                        >
                                          Join
                                        </Button>
                                      </Grid>{' '}
                                    </Grid>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Fragment>
                        </Form>
                      </Formik>
                    </Fragment>
                  );
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={appointmentDialog}>
        <DialogTitle>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>Appointment Details</Box>
            <IconButton onClick={closeAppointmentDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>{dialogContent}</DialogContent>
      </Dialog>
    </div>
  );
};

export default MyCalendar;
