// --- Imports --- //
import * as types from './Types';

const initialState = {
  selectedBranch: {
    id: '1af36ccc-909e-4866-831c-410422634f45',
    name: 'Albany',
  },
  branches: [],
  purposes: [],
  selectedEvent: {},
  addEventData: {},
  currentView: 'week',
  mode: 'normal',
  rescheduleInfo: {},
  templateSlots: [],
  loggedIn: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECTED_EVENT:
      return { ...state, selectedEvent: action.data };
    case types.SET_ALL_BRANCHES:
      return { ...state, branches: action.data };
    case types.SET_ALL_PURPOSES:
      return { ...state, purposes: action.data };
    case types.SET_SELECTED_BRANCH:
      return { ...state, selectedBranch: action.data };
    case types.SET_ADD_EVENT_DATA:
      return { ...state, addEventData: action.data };
    case types.SET_CURRENT_VIEW:
      return { ...state, currentView: action.data };
    case types.SET_MODE:
      return { ...state, mode: action.data };
    case types.SET_RESCHEDULE_INFO:
      return { ...state, rescheduleInfo: action.data };
    case types.SET_TEMPLATE_SLOTS:
      return { ...state, templateSlots: action.data };
    case types.SET_LOGGED_IN:
      return { ...state, loggedIn: action.data };
    default:
      return state;
  }
};

export default reducer;
