export const alpha_num_limited = {
  regex: /^([a-zA-Z0-9'.\s$%&#?,"\-_])+$|(^$)/,
  message: 'Only letters, numbers, spaces, and limited punctuation are allowed',
};

export const number = {
  regex: /^([0-9])+$|(^$)/,
  message: 'Only numbers are allowed.',
};

export const month = {
  regex: /^(0[1-9])|(10)|(11)|(12)|(^$)/,
  message: 'Only months numbers are allow. (ex: 09 for september)',
};

export const socialSecurityNumber = {
  regex: /^\d{9}$/,
  message: 'Social Security Number must be formatted as xxx - xx - xxxx',
};

export const zip = {
  regex: /^(\d{5}(?:[-]\d{4})?$)|(^$)/,
  message: 'Zip code must be formatted as XXXXX or XXXXX-XXXX',
};

export const phone = {
  regex: /^(\d{10}$)|(^$)/,
  message: 'Phone number must be (xxx) xxx-xxxx',
};

export const creditCardExpiration = {
  regex: /(0[1-9]|(10)|(11)|(12)){1,2}[1-9]\d{3}/,
  message: 'Credit Card Expiration date must be formatted as mm/yyyy',
};
