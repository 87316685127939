/* eslint-disable no-unused-vars */
// --- Imports --- //
import axios from 'axios';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// -- Services --- //
// import LogService, { useLogService } from 'services/LogService';

const TemplateService = class {
  // constructor(logService, recaptchaService) {
  constructor() {
    // Axios client initialization
    this.client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_SLOT_API_VERSION}/template`,
      validateStatus: function () {
        return true;
      },
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_API_SUB_KEY}` }
    });

    // ReCaptcha Service
    // this.recaptchaService = recaptchaService || function () { };

    //Log Service
    // this.logService = logService || null;
  }

  //gets template slots for a given branch
  async getTemplateSlots(branchId, startOfWeek, token) {
    try {
      const { data: response, status } = await this.client.get('', {
        params: {
          branchId: branchId,
          startOfWeek: startOfWeek,
        },
        headers: { Authorization: 'bearer ' + token },
      });

      // console.log('STATUS', status);
      // console.log('RESPONSE', response);
      return response;
    } catch (error) {
      // this.logService.create('ERROR', 'Error Message', error);
      // console.log('ERROR:', error);
      return {};
    }
  }

  //Updates template slots for a given branch
  async updateTemplateSlots(branchId, templateSlots, token) {
    try {
      const { data: response, status } = await this.client.post(
        '/update',
        templateSlots,
        {
          params: {
            branchId: branchId,
          },
          headers: { Authorization: 'bearer ' + token },
        }
      );
      // console.log('RESPONSE:', response);
      return response;
    } catch (error) {
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }

  //Applies template to an empty week
  async applyTemplateToWeek(branchId, startOfWeek, token) {
    try {
      const { data: response, status } = await this.client.post(
        '/apply',
        {},
        {
          params: {
            branchId: branchId,
            startOfWeek: startOfWeek,
          },
          headers: { Authorization: 'bearer ' + token },
        }
      );
      // console.log('RESPONSE:', response);
      return response;
    } catch (error) {
      // this.logService.create('ERROR', 'Error Message', error);
      return {};
    }
  }
};

export const useTemplateService = (props) => {
  //const { executeRecaptcha } = useGoogleReCaptcha();
  //const logService = useLogService('ApplicationService');

  // return new TemplateService(logService, executeRecaptcha);
  return new TemplateService();
};

// export default new ApplicationService(LogService('ApplicationService'));
export default new TemplateService();
