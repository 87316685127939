// --- Imports --- //
import React from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { useAuth } from 'services/AuthService';


// --- Material Ui Imports --- //

// --- Custom Component Imports --- //

// --- Utilities Import --- //

// --- Styles --- //

// --- Component --- //
const ProtectedRoute = props => {

  const { children, component: Component, layout: Layout, ...rest } = props;
  const hasLayout = Boolean(Layout);

  // --- Hooks --- //
  const history = useHistory();

  // --- Services --- //
  const { isAuthenticated, checkForSavedAuthentication } = useAuth();

  /**
 * Redirect to landing page if not authenticated
 */
  if (!isAuthenticated) {
    checkForSavedAuthentication().then(response => {
      if (!response) {
        history.push('/');
      }
    });
  }

  return (
    <Route
      {...rest}
      render={matchProps => {
        // KEEP: to be implemented later
        // if (auth.isAuthenticated && hasLayout) {
        //   return (
        //     <Layout>
        //       <Component />
        //     </Layout>
        //   );
        // } else if (auth.isAuthenticated) {
        //   return <Component />;
        // }

        // KEEP: to be implemented later
        // return (
        //   <Redirect to={{ pathname: '/log-in', state: { from: location } }} />
        // );

        if (hasLayout) {
          return (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          );
        }

        return <Component {...matchProps} />;
      }}
    />
  );
};

// --- Component Props --- //
ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

// ProtectedRoute.defaultProps  = {
//
// }

// --- Store Connection --- //
// const StatefulProtectedRoute = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ProtectedRoute);

// --- Exports --- //
export default ProtectedRoute;
// export default StatefulProtectedRoute;
