/* eslint-disable no-unused-vars */
// --- Imports --- //
import axios from 'axios';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// -- Services --- //
// import LogService, { useLogService } from 'services/LogService';

const BranchService = class {
  // constructor(logService, recaptchaService) {

  constructor() {
    // Axios client initialization
    this.client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_SLOT_API_VERSION}/branch`,
      validateStatus: function () {
        return true;
      },
      headers: { 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_API_SUB_KEY}` }
    });
    // ReCaptcha Service
    // this.recaptchaService = recaptchaService || function () { };
    //Log Service
    // this.logService = logService || null;
  }

  async getBranches(lang) {
    try {
      const { data: response, status } = await this.client.get('/get', {
        params: {
          locale: lang
        },});
      return response;
    } catch (error) {
      // console.log('error', error);
      return {};
    }
  }
};

export const useBranchService = (props) => {
  //const { executeRecaptcha } = useGoogleReCaptcha();
  //const logService = useLogService('BranchService');

  // return new BranchService(logService, executeRecaptcha);
  return new BranchService();
};

// export default new BranchService(LogService('BranchService'));
export default new BranchService();
