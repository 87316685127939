import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import palette from 'themes/palette';

//Components
import { Subtitle, CustomPopUp, CustomPopUpAlert } from 'components';

//Icons
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VideocamIcon from '@material-ui/icons/Videocam';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ChatIcon from '@material-ui/icons/Chat';

//Material UI
import {
  Typography,
  Box,
  Button,
  TextField as MuiTextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form } from 'formik';

//Services
import { useReservationService } from 'services/ReservationService';
import { useAuth } from 'services/AuthService';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';

import { useTranslation, Trans } from 'react-i18next';

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    marginBottom: '15px',
  },

  attendeeRow: {
    borderBottom: '1px solid #ededed',
    marginBottom: '10px',
    paddingBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px',
    },
  },
  attendeeRowTwo: {
    borderLeft: '3px solid ' + palette.forest.main,
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '10px',
    marginTop: '10px',
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px',
    },
  },
  attendeeRowTop: {
    borderTop: '1px solid #ededed',
    paddingTop: '10px',
  },
  attendeeName: {
    fontWeight: '500',
    color: palette.forest.dark,
    fontSize: '120%',
  },
  attendeeDetails: {
    fontWeight: 300,
  },
  attendeeButtons: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  attendeeInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
    '&:not(:first-child)': {
      marginTop: '5px',
    },
  },
  icon: {
    marginRight: '5px',
    color: palette.highway.main,
  },
  messageBox: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
  },
  successText: {
    color: '#597f54',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CurrentReservations = (props) => {
  const classes = useStyles();
  const { close, ...rest } = props;
  const { isAuthenticated, getTokenForApi } = useAuth();

  //State Hooks
  //Yes/No Confirm Popup
  const [customPopUp, setCustomPopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpContent, setPopUpContent] = useState('');
  const [popUpActions, setPopUpActions] = useState('');
  //Alert Popup
  const [customPopUpAlert, setCustomPopUpAlert] = useState(false);
  const [popUpAlertTitle, setPopUpAlertTitle] = useState('');
  const [popUpAlertContent, setPopUpAlertContent] = useState('');
  const [popUpAlertType, setPopUpAlertType] = useState('neutral');

  //Redux Hooks
  const dispatch = useDispatch();
  const selectedEvent = useSelector((state) => state.reducer.selectedEvent);
  const purposes = useSelector((state) => state.reducer.purposes);

  //Service Hooks
  const reservationService = useReservationService();

  //Custom PopUp Helpers
  const openCustomPopUp = () => setCustomPopUp(true);
  const closeCustomPopUp = () => setCustomPopUp(false);
  const closeCustomPopUpWithAction = (
    result,
    resultAction,
    functionParams = []
  ) => {
    resultAction(result, ...functionParams);
    setCustomPopUp(false);
  };
  //Custom Alert PopUp Helpers
  const openCustomPopUpAlert = () => setCustomPopUpAlert(true);
  const closeCustomPopUpAlert = () => setCustomPopUpAlert(false);

  const { t, i18n } = useTranslation();

  const cancelAppointment = (result, id) => {
    if (result) {
      getTokenForApi().then((token) => {
        if (isAuthenticated && token !== '') {
          reservationService
            .cancelReservationByEmployee(id, true, token)
            .then((response) => {
              const newReservations = selectedEvent.reservations.filter(
                (reservation) => reservation.id !== response.data
              );
              const newEventWithCancel = {
                ...selectedEvent,
                available: selectedEvent.available + 1,
                reservations: newReservations,
              };
              dispatch(actions.setSelectedEvent(newEventWithCancel));

              //Set success message
              setPopUpAlertTitle(i18n.t('success'));
              setPopUpAlertContent(i18n.t('successfullyCancelled'));
              setPopUpAlertType('success');
              openCustomPopUpAlert();
            });
        }
      });
    }
  };

  const cancelAppointmentButtonClick = (id) => {
    setPopUpTitle(
      <Box className={classes.flex}>
        <WarningRoundedIcon style={{ marginRight: '10px' }} fontSize="large" />
        Warning
      </Box>
    );
    setPopUpContent(i18n.t('areYouSureYouWantToCancelThisAppointment'));
    setPopUpActions(
      <Fragment>
        <Button
          onClick={() => {
            closeCustomPopUpWithAction(true, cancelAppointment, [id]);
          }}
          variant="contained"
          color="primary"
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            closeCustomPopUp(false);
          }}
          variant="outlined"
          color="primary"
        >
          No
        </Button>
      </Fragment>
    );
    openCustomPopUp();
  };

  function maskPhoneNumber(string) {
    return (
      '(' +
      string.slice(0, 3) +
      ') ' +
      string.slice(3, 6) +
      '-' +
      string.slice(6, 10)
    );
  }

  const rescheduleAppointment = (reservation) => {
    //Set redux reschedule info
    dispatch(actions.setRescheduleInfo(reservation));
    //Set redux mode to reschedule
    dispatch(actions.setMode('reschedule'));
    //Close dialog to see calendar view in reschedule mode
    props.close();
  };

  return (
    <div className={classes.box}>
      <CustomPopUpAlert
        open={customPopUpAlert}
        title={popUpAlertTitle}
        type={popUpAlertType}
        onClose={closeCustomPopUpAlert}
        disableClose
      >
        {popUpAlertContent}
      </CustomPopUpAlert>
      <CustomPopUp
        open={customPopUp}
        title={popUpTitle}
        actions={popUpActions}
        alert
        disableClose
      >
        {popUpContent}
      </CustomPopUp>
      <Subtitle>Current Reservations </Subtitle>
      <Box className={classes.rows}>
        {selectedEvent.reservations.length === 0
          ? i18n.t('noOneSignedUp')
          : selectedEvent.reservations.map((reservation, index) => {
              return (
                <Box
                  key={index}
                  className={classes.attendeeRowTwo}
                  // className={
                  //   index === 0
                  //     ? clsx(classes.attendeeRow, classes.attendeeRowTop)
                  //     : classes.attendeeRow
                  // }
                >
                  <Box className={classes.attendeeInfo}>
                    <Box>
                      <Box className={clsx(classes.item, classes.attendeeName)}>
                        <PersonIcon className={classes.icon} fontSize="small" />
                        {reservation.name}
                      </Box>
                      <Box
                        className={clsx(classes.item, classes.attendeeDetails)}
                      >
                        <EmailIcon className={classes.icon} fontSize="small" />
                        {reservation.email}
                      </Box>
                      <Box
                        className={clsx(classes.item, classes.attendeeDetails)}
                      >
                        <PhoneIcon className={classes.icon} fontSize="small" />
                        {maskPhoneNumber(reservation.phoneNumber)}
                      </Box>
                      <Box
                        className={clsx(classes.item, classes.attendeeDetails)}
                      >
                        <ChatBubbleIcon
                          className={classes.icon}
                          fontSize="small"
                        />
                        {
                          purposes.find(
                            (purpose) => purpose.id === reservation.purposeId
                          ).title
                        }
                      </Box>
                      {/* <Box className={classes.item}>
                        <ChatIcon className={classes.icon} fontSize="small" />
                        {reservation.comment !== ''
                          ? reservation.comment
                          : 'No Comment provided'}
                      </Box> */}
                      <Box
                        className={clsx(classes.item, classes.attendeeDetails)}
                      >
                        <i
                          className={clsx('fas fa-hashtag', classes.icon)}
                          style={{
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        ></i>
                        {reservation.memberNumber !== ''
                          ? reservation.memberNumber
                          : i18n.t('noMemberNumberProvided')}
                      </Box>
                      {reservation.isVideo ? (
                        <Box
                          className={clsx(
                            classes.item,
                            classes.attendeeDetails
                          )}
                        >
                          <VideocamIcon
                            className={classes.icon}
                            fontSize="small"
                          />
                          i18n.t('videoAppointment')
                        </Box>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.attendeeButtons}>
                    {selectedEvent.end > moment().toDate() ? (
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginBottom: '8px' }}
                        size="small"
                        onClick={() =>
                          cancelAppointmentButtonClick(reservation.id)
                        }
                      >
                        {t('cancelReservation')}
                      </Button>
                    ) : (
                      ''
                    )}

                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => rescheduleAppointment(reservation)}
                    >
                      {t('rescheduleReservation')}
                    </Button>
                  </Box>
                </Box>
              );
            })}
        {/* {showMessage ? (
          <Box item xs={12}>
            {message}
          </Box>
        ) : (
          ''
        )} */}
      </Box>
    </div>
  );
};

export default CurrentReservations;
