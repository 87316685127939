import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

//Components
import { Subtitle } from 'components';

//Material UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Button,
  TextField as MuiTextField,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form } from 'formik';

//Schema
import AddTemplateSlotSchema from './AddTemplateSlotSchema';

//Services
import { useAuth } from 'services/AuthService';
import { useTemplateService } from 'services/TemplateService';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    marginBottom: '15px',
  },
  note: {
    fontStyle: 'italic',
    fontWeight: 300,
    lineHeight: 1.3,
    letterSpacing: '0.1px',
  },
}));

const AddTemplateSlot = (props) => {
  const classes = useStyles();
  const { isAuthenticated, getTokenForApi } = useAuth();
  const { event, close, type, ...rest } = props;

  //Redux Hooks
  const dispatch = useDispatch();
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);
  const templateSlots = useSelector((state) => state.reducer.templateSlots);

  //Service Hooks
  const templateService = useTemplateService();

  const week = new Array(
    { name: 'Sunday', value: 0 },
    { name: 'Monday', value: 1 },
    { name: 'Tuesday', value: 2 },
    { name: 'Wednesday', value: 3 },
    { name: 'Thursday', value: 4 },
    { name: 'Friday', value: 5 },
    { name: 'Saturday', value: 6 }
  );

  const onSubmit = (values) => {
    getTokenForApi()
      .then((token) => {
        if (isAuthenticated && token !== '') {
          const beginningOfTime = moment()
            .year(2000)
            .dayOfYear(1)
            .hour(0)
            .minute(0)
            .seconds(0)
            .milliseconds(0);

          let startDateOffset = moment();
          let newEvent = {};
          const sunday = moment().startOf('week');

          //If add type is dragging on calendar
          if (type === 'drag') {
            const eventStart = moment(event.start);
            const difference = eventStart.diff(sunday);
            const duration = moment.duration(difference);

            startDateOffset = beginningOfTime
              .add(duration._data.days, 'days')
              .add(duration._data.hours, 'hours')
              .add(duration._data.minutes, 'minutes')
              .toDate();

            newEvent = {
              //Important for API
              startDateOffset: startDateOffset,
              duration: values.duration,
              quantity: parseInt(values.quantity),
              //Important for calendar display
              id: uuid(),
              start: eventStart.toDate(),
              title: 'Appointment',
              end: moment(eventStart).add(values.duration, 'minutes').toDate(),
              changed: true,
            };
          } else {
            //If the add type is clicking add slot button
            const hour = parseInt(values.startTime.substring(0, 2));
            const minute = parseInt(values.startTime.substring(3, 5));
            startDateOffset = beginningOfTime
              .add(values.dayOfWeek, 'days')
              .add(hour, 'hours')
              .add(minute, 'minutes')
              .toDate();

            const startDate = sunday
              .add(values.dayOfWeek, 'days')
              .add(hour, 'hours')
              .add(minute, 'minutes');

            newEvent = {
              //Important for API
              startDateOffset: startDateOffset,
              duration: values.duration,
              quantity: parseInt(values.quantity),
              //Important for calendar display
              id: uuid(),
              start: startDate.toDate(),
              title: 'Appointment',
              end: startDate.add(values.duration, 'minutes').toDate(),
              changed: true,
            };
          }
          dispatch(actions.setTemplateSlots([...templateSlots, newEvent]));
          close();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div className={classes.box}>
      <Formik
        initialValues={
          type === 'drag'
            ? {
                quantity: '',
                dayOfWeek: moment(event.start).format('d'),
                startTime: moment(event.start).format('HH:mm'),
                duration: moment(event.end).diff(event.start, 'minutes'),
              }
            : {
                quantity: '',
                dayOfWeek: '',
                startTime: '',
                duration: '',
              }
        }
        enableReinitialize
        validationSchema={AddTemplateSlotSchema}
        onSubmit={(values) => {
          // console.log('Submitted Values', values);
          onSubmit(values);
        }}
      >
        <Form>
          <Fragment>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                {' '}
                <TextField
                  name="dayOfWeek"
                  label="Day Of Week"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={type === 'drag' ? true : false}
                  select
                >
                  {week.map((day, index) => (
                    <MenuItem key={index} value={day.value}>
                      {day.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="startTime"
                  label="Start Time"
                  helperText={
                    type === 'button' ? 'Example Format: 11:30 AM' : ''
                  }
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: '06:00',
                    max: '21:00',
                  }}
                  variant="outlined"
                  fullWidth
                  disabled={type === 'drag' ? true : false}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="duration"
                  label="Duration in Minutes"
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">minutes</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="quantity"
                  label="Number of Appointments"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" variant="contained" type="submit">
                  Add Slot to {selectedBranch.name}'s Template
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        </Form>
      </Formik>
    </div>
  );
};

export default AddTemplateSlot;
