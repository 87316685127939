// --- Yup/Validation Imports --- //
import * as yup from 'yup';
import { phone } from 'utilities/validation/regex';

const AddTemplateSlotSchema = yup.object().shape({
  dayOfWeek: yup.string().label('Day of Week').required(),
  duration: yup.string().label('Duration').required(),
  startTime: yup.string().label('Start Time').required(),
  quantity: yup.number().integer().label('Number of Appointments').required(),
});

export default AddTemplateSlotSchema;
