import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import momentTZ from 'moment-timezone';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

//Components
import { Subtitle, TextMaskCustom, CustomPopUpAlert } from 'components';

//Material UI
import {
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  MenuItem,
  Grid,
  Chip,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form } from 'formik';

//Theme
import palette from 'themes/palette';

// Model & Schema
import ReserveFormModel from './ReserveFormModel';
import ReserveFormSchema from './ReserveFormSchema';

//Services
import { useSlotService } from 'services/SlotService';
import { usePurposeService } from 'services/PurposeService';
import { useReservationService } from 'services/ReservationService';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    marginBottom: '15px',
  },
  chipContainer: {
    '& > *': {
      margin: theme.spacing(0.5),
      // backgroundColor: palette.forest.main,
      // color: 'white',
    },
  },
  chip: {
    marginRight: '5px',
  },
  messageBox: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
  },
  successText: {
    color: '#597f54',
  },
  errorText: {
    color: '#e53935',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ReserveForm = (props) => {
  const classes = useStyles();
  const { setLastUpdated, ...rest } = props;
  const history = useHistory();
  const [purposes, setPurposes] = useState([]);

  //Redux Hooks
  const dispatch = useDispatch();
  const selectedEvent = useSelector((state) => state.reducer.selectedEvent);
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);
  const mode = useSelector((state) => state.reducer.mode);
  const rescheduleInfo = useSelector((state) => state.reducer.rescheduleInfo);

  //Service Hooks
  const slotService = useSlotService();
  const purposeService = usePurposeService();
  const reservationService = useReservationService();

  //State Hooks
  const [loading, setLoading] = useState(false);
  // const [showMessage, setShowMessage] = useState(false);
  // const [message, setMessage] = useState('');
  const [customPopUpAlert, setCustomPopUpAlert] = useState(false);
  const [popUpAlertTitle, setPopUpAlertTitle] = useState('');
  const [popUpAlertContent, setPopUpAlertContent] = useState('');
  const [popUpAlertType, setPopUpAlertType] = useState('neutral');
  const [showOtherPurposeArea, setShowOtherPurposeArea] = useState(false);


  //Custom Alert PopUp Helpers
  const openCustomPopUpAlert = () => setCustomPopUpAlert(true);
  const closeCustomPopUpAlert = () => setCustomPopUpAlert(false);

  const { t, i18n } = useTranslation();


  //moment.locale(i18n.language);
    var theDate = moment(selectedEvent.start);

    if (purposes.length === 0)
    purposeService.getPurposes(moment.locale()).then((response) => {
      setPurposes(response.data);
    });
  
  const purposeChange = (val) => {
    // this guid is the id of 'Other' purpose
    if(val.target.value === "1273B5F9-5645-4A86-9631-FD2469E48282".toLocaleLowerCase())
    {
      setShowOtherPurposeArea(true);
    }
    else{
      setShowOtherPurposeArea(false);
    }
  }

  const submitReserveForm = (values, resetForm) => {
    setLoading(true);
    // let d = new Date();
    // const offset = d.getTimezoneOffset();
    var offsetInMinutes = momentTZ(selectedEvent.start).tz('America/Los_Angeles').utcOffset() * -1;

    const formValues = {
      ...values,
      slotId: selectedEvent.id,
      timezoneOffsetMinutes: offsetInMinutes,
    };
    slotService.reserve(formValues).then((result) => {
      setLastUpdated(moment().toDate());
      if (result.data !== null) {
        dispatch(
          actions.setSelectedEvent({
            ...selectedEvent,
            available: selectedEvent.available - 1,
            reservations: [
              ...selectedEvent.reservations,
              { ...formValues, id: result.data },
            ],
          })
        );
        setLoading(false);
        // setShowMessage(true);

        if (mode === 'reschedule') {
          //cancel existing appointment
          reservationService
            .cancelReservation(rescheduleInfo.id, true)
            .then((response) => {});
          dispatch(actions.setMode('normal'));
          dispatch(actions.setRescheduleInfo({}));
          //Set popup content, title, and actions
          setPopUpAlertTitle(i18n.t('success'));
          setPopUpAlertContent(
            ' ' + i18n.t('successfullyRescheduled')
          );
          setPopUpAlertType('success');
          openCustomPopUpAlert();

          // setMessage(
          //   <Box className={classes.messageBox}>
          //     <CheckCircleIcon
          //       fontSize="large"
          //       style={{ marginRight: '10px', color: '#597f54' }}
          //     />
          //     <Box className={classes.successText}>
          //       Successfully rescheduled appointment. Leaving Reschedule Mode
          //       now.
          //     </Box>
          //   </Box>
          // );
        } else {
          setPopUpAlertTitle(i18n.t('success'));
          setPopUpAlertContent(' ' + i18n.t('successfullyReserved'));
          setPopUpAlertType('success');
          openCustomPopUpAlert();
          // setMessage(
          //   <Box className={classes.messageBox}>
          //     <CheckCircleIcon
          //       fontSize="large"
          //       style={{ marginRight: '10px', color: '#597f54' }}
          //     />
          //     <Box className={classes.successText}>
          //       Successfully reserved appointment!{' '}
          //     </Box>
          //   </Box>
          // );
        }
      }
      //IF FAILURE
      else {
        setLoading(false);
        // setShowMessage(true);

        setPopUpAlertTitle(i18n.t('error'));
        setPopUpAlertContent(
          i18n.t('appointmentNoLongerAvailable')
        );
        setPopUpAlertType('error');
        openCustomPopUpAlert();
        // setMessage(
        //   <Box className={classes.messageBox}>
        //     <ErrorIcon
        //       style={{ marginRight: '10px', color: '#e53935' }}
        //       fontSize="large"
        //     />
        //     <Box className={classes.errorText}>
        //       Oops! We're sorry, but it looks like this appointment is no longer
        //       available. Please close this pop-up and choose another slot.
        //     </Box>
        //   </Box>
        // );
      }
      history.push('/reservation/' + result.data);
      resetForm();
    });
  };

  return (
    <div className={classes.box}>
      <CustomPopUpAlert
        open={customPopUpAlert}
        title={popUpAlertTitle}
        type={popUpAlertType}
        onClose={closeCustomPopUpAlert}
        disableClose
      >
        {popUpAlertContent}
      </CustomPopUpAlert>
      <Formik
        initialValues={
          mode === 'reschedule'
            ? new ReserveFormModel({
                name: rescheduleInfo.name,
                email: rescheduleInfo.email,
                phoneNumber: rescheduleInfo.phoneNumber,
                memberNumber: rescheduleInfo.memberNumber,
                purposeId: rescheduleInfo.purposeId,
                isVideo: rescheduleInfo.isVideo,
              }).toObject()
            : new ReserveFormModel().toObject()
        }
        validationSchema={ReserveFormSchema}
        onSubmit={(values, { resetForm }) => {
          submitReserveForm(values, resetForm);
        }}
      >
        <Form>
          <Fragment>
            <Subtitle>{t('reserveAppt')}</Subtitle>
            <Box
              style={{ marginBottom: '15px' }}
              className={classes.chipContainer}
            >
              <Chip
                label={theDate.format('dddd MMM D, YYYY')}
                size="small"
              />
              <Chip
                label={moment(selectedEvent.start).format('hh:mm A')}
                size="small"
              />
              <Chip
                label={
                  moment(selectedEvent.end).diff(
                    selectedEvent.start,
                    'minutes'
                  ) + ' ' + i18n.t('minutes')
                }
                size="small"
              />
              <Chip label={selectedBranch.name + ' ' + i18n.t('branch')} size="small" />
              <Chip
                label={
                  selectedEvent.available +
                  ' ' +
                  (selectedEvent.available === 1
                    ? i18n.t('appointment').toLowerCase() + ' '
                    : i18n.t('appointments').toLowerCase() + ' ') +
                  i18n.t('available')
                }
                size="small"
              />
            </Box>

            <Box
              style={{
                borderTop: '1px solid #cccccc',
                marginTop: '10px',
              }}
            >
              <Box>
                {selectedEvent.available === 0 ? (
                  <Box
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    There are no available appointments at this time. Please choose another slot.
                  </Box>
                ) : (
                  <Box
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          id="name"
                          name="name"
                          label={t('name')}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="memberNumber"
                          label={t('memberId')}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="email"
                          label={t('email')}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextMaskCustom
                          name="phoneNumber"
                          label={t('phoneNumber')}
                          fullWidth
                          variant="outlined"
                          maskType="phone"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="purposeId"
                          select
                          label={t('appointmentReason')}
                          variant="outlined"
                          onClick={purposeChange}
                          fullWidth
                        >
                          {purposes.map((purpose) => (
                            <MenuItem key={purpose.id} value={purpose.id}>
                              {purpose.title}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {showOtherPurposeArea && (
                      <Grid item xs={12}>
                        <TextField
                          name="purposeOtherText"
                          label="Other Reason"
                          variant="outlined"
                          multiline
                          rows={3}
                          fullWidth
                        />
                      </Grid>
                      )}
                      {/* Not doing video appointments yet */}
                      {/* <Grid item xs={12}>
                        <FormControlLabel
                          //   style={{ marginTop: '10px' }}
                          control={<FormikCheckbox name="isVideo" />}
                          label="I would like a video appointment instead of an in-person appointment."
                        />
                      </Grid> */}
                      {/* Not doing textReminders yet */}
                      {/* <Grid item xs={12}>
                        <FormControlLabel
                          //   style={{ marginTop: '10px' }}
                          control={<FormikCheckbox name="textReminders" />}
                          label="I would like to receive text reminders."
                        />
                      </Grid> */}
                      <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Button
                          disabled={
                            selectedEvent.available === 0 ? true : false
                          }
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          {mode === 'reschedule' ? i18n.t('reschedule') : i18n.t('reserve')}
                        </Button>
                      </Grid>{' '}
                    </Grid>
                  </Box>
                )}
                <Box style={{ marginTop: loading ? '20px' : '0' }}>
                  {loading && (
                    <CircularProgress
                      style={{ marginLeft: '20px' }}
                      color="secondary"
                    />
                  )}

                  {/* {showMessage ? <Box>{message}</Box> : ''} */}
                </Box>
              </Box>
            </Box>
          </Fragment>
        </Form>
      </Formik>
    </div>
  );
};

export default ReserveForm;
