import React, { useState, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';

//Components
import { Subtitle, CustomPopUp, CustomPopUpAlert } from 'components';

//Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

//Material UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  TextField as MuiTextField,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form } from 'formik';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store';

//Services
import { useAuth } from 'services/AuthService';

const EditSlotSchema = yup.object().shape({
  quantity: yup.number().integer().label('Number of Appointments').required(),
});

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    marginBottom: '15px',
  },
  messageBox: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
  },
  successText: {
    color: '#597f54',
  },
  errorText: {
    color: '#e53935',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const EditSlot = (props) => {
  const classes = useStyles();
  const { slotService, close, ...rest } = props;
  const { isAuthenticated, getTokenForApi } = useAuth();

  //Redux Hooks
  const dispatch = useDispatch();
  const selectedEvent = useSelector((state) => state.reducer.selectedEvent);

  //State Hooks
  // const [message, setMessage] = useState('');
  // const [showMessage, setShowMessage] = useState(false);
  //Yes/No Popup
  const [customPopUp, setCustomPopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpContent, setPopUpContent] = useState('');
  const [popUpActions, setPopUpActions] = useState('');
  //Alert Popup
  const [customPopUpAlert, setCustomPopUpAlert] = useState(false);
  const [popUpAlertTitle, setPopUpAlertTitle] = useState('');
  const [popUpAlertContent, setPopUpAlertContent] = useState('');
  const [popUpAlertType, setPopUpAlertType] = useState('neutral');

  const numReservations = selectedEvent.reservations.length;

  //Custom PopUp Helpers
  const openCustomPopUp = () => setCustomPopUp(true);
  const closeCustomPopUp = () => setCustomPopUp(false);
  const closeCustomPopUpWithAction = (
    result,
    resultAction,
    functionParams = []
  ) => {
    resultAction(result, ...functionParams);
    setCustomPopUp(false);
  };

  //Custom Alert PopUp Helpers
  const openCustomPopUpAlert = () => setCustomPopUpAlert(true);
  const closeCustomPopUpAlert = () => setCustomPopUpAlert(false);

  const deleteSlot = (result, token) => {
    if (result) {
      slotService.deleteSlot(selectedEvent.id, token).then((response) => {
        props.close();
      });
    }
  };

  const deleteSlotButtonClick = () => {
    getTokenForApi()
      .then((token) => {
        if (isAuthenticated && token !== '') {
          if (numReservations !== 0) {
            setPopUpAlertTitle("Error");
            setPopUpAlertContent(
              ' You cannot delete the slot when there are reservations. Either cancel or reschedule reservations in order to remove the slot.'
            );
            setPopUpAlertType('error');
            openCustomPopUpAlert();
            // setMessage(
            //   <Box className={classes.messageBox}>
            //     <ErrorIcon
            //       style={{ marginRight: '10px', color: '#e53935' }}
            //       fontSize="large"
            //     />
            //     <Box className={classes.errorText}>
            //       You cannot delete the slot when there are reservations. Either
            //       cancel or reschedule reservations in order to remove the slot.
            //     </Box>
            //   </Box>
            // );
            // setShowMessage(true);
          } else {
            setPopUpTitle(
              <Box className={classes.flex}>
                <WarningRoundedIcon
                  style={{ marginRight: '10px' }}
                  fontSize="large"
                />
                Warning
              </Box>
            );
            setPopUpContent('Are you sure you want to delete this slot? ');
            setPopUpActions(
              <Fragment>
                <Button
                  onClick={() => {
                    closeCustomPopUpWithAction(true, deleteSlot, [token]);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    closeCustomPopUp(false);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  No
                </Button>
              </Fragment>
            );
            openCustomPopUp();
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const updateQuantity = (quantity, resetForm) => {
    getTokenForApi()
      .then((token) => {
        if (isAuthenticated && token !== '') {
          if (quantity < numReservations) {
            setPopUpAlertTitle("Error");
            setPopUpAlertContent(
              'You cannot make the number of appointments less than the number of current reservations. Either cancel or reschedule reservations in order to lower the quantity.    '
            );
            setPopUpAlertType('error');
            openCustomPopUpAlert();
            // setMessage(
            //   <Box className={classes.messageBox}>
            //     <ErrorIcon
            //       style={{ marginRight: '10px', color: '#e53935' }}
            //       fontSize="large"
            //     />
            //     <Box className={classes.errorText}>
            //       You cannot make the number of reservations less than the
            //       number of current reservations. Either cancel or reschedule
            //       reservations in order to lower the quantity.
            //     </Box>
            //   </Box>
            // );
            // setShowMessage(true);
            resetForm();
          } else {
            //Call API
            slotService
              .updateQuantity(selectedEvent.id, quantity, token)
              .then((response) => {
                //IF SUCCESS
                if (response.status === 200) {
                  //Updating redux state for displaying the correct number of appointments
                  dispatch(
                    actions.setSelectedEvent({
                      ...selectedEvent,
                      quantity: quantity,
                      available: quantity - selectedEvent.reservations.length,
                    })
                  );
                  setPopUpAlertTitle('Success');
                  setPopUpAlertContent('Successfully updated quantity!');
                  setPopUpAlertType('success');
                  openCustomPopUpAlert();
                  // setMessage(
                  //   <Box className={classes.messageBox}>
                  //     <CheckCircleIcon
                  //       fontSize="large"
                  //       style={{ marginRight: '10px', color: '#597f54' }}
                  //     />
                  //     <Box className={classes.successText}>
                  //       Successfully updated quantity!
                  //     </Box>
                  //   </Box>
                  // );
                }
                //IF FAILURE
                else {
                  //Set error message
                  setPopUpAlertTitle('Error');
                  setPopUpAlertContent(
                    'There was a problem trying to update quantity.'
                  );
                  setPopUpAlertType('error');
                  openCustomPopUpAlert();
                  // setMessage(
                  //   <Box className={classes.messageBox}>
                  //     <ErrorIcon
                  //       style={{ marginRight: '10px', color: '#e53935' }}
                  //       fontSize="large"
                  //     />
                  //     <Box className={classes.errorText}>
                  //       There was a problem trying to update quantity.
                  //     </Box>
                  //   </Box>
                  // );
                }

                // setShowMessage(true);
                resetForm({ values: { quantity: quantity } });
              });
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div className={classes.box}>
      <CustomPopUpAlert
        open={customPopUpAlert}
        title={popUpAlertTitle}
        type={popUpAlertType}
        onClose={closeCustomPopUpAlert}
        disableClose
      >
        {popUpAlertContent}
      </CustomPopUpAlert>
      <CustomPopUp
        open={customPopUp}
        title={popUpTitle}
        actions={popUpActions}
        alert
        disableClose
      >
        {popUpContent}
      </CustomPopUp>
      <Formik
        initialValues={{ quantity: selectedEvent.quantity }}
        validationSchema={EditSlotSchema}
        onSubmit={(values, { resetForm }) => {
          // console.log('Submitted Values', values);
          updateQuantity(values.quantity, resetForm);
        }}
      >
        <Form>
          <Fragment>
            <Subtitle>Edit Slot</Subtitle>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                {' '}
                <TextField
                  name="quantity"
                  label="Number of Appointments"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              </Grid>
              {/* {showMessage ? (
                <Grid item xs={12}>
                  {message}
                </Grid>
              ) : (
                ''
              )} */}

              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ marginRight: '15px' }}
                >
                  Update
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={deleteSlotButtonClick}
                >
                  Remove Slot From Calendar
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        </Form>
      </Formik>
    </div>
  );
};

export default EditSlot;
