import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// --- React Router --- //
import { useHistory, useLocation, matchPath } from 'react-router-dom';

// --- Auth --- //
import {
  default as Auth,
  GRAPH_REQUESTS,
  GRAPH_ENDPOINTS,
  GRAPH_SCOPES,
  requiresInteraction,
  fetchMsGraph,
  isIE,
  acquireToken,
  getAccount,
} from './AuthService';

import { useAppSettingsService } from 'services/AppSettingsService';

// --- Creating Context --- //
const AuthContext = createContext();

// --- Naming Context --- //
AuthContext.displayName = 'AuthContext';

// --- Extracting Provider and Consumer --- //
const Provider = AuthContext.Provider;
const Consumer = AuthContext.Consumer;

export const AuthProvider = (props) => {
  // --- States --- //
  const redirect = process.env.REACT_APP_AD_USE_REDIRECT || isIE() || false;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [account, setAccount] = useState({});
  const [user, setUser] = useState({});
  const [token, setToken] = useState({});
  const [checkingLogin, setCheckingLogin] = useState(false);
  const [appSettings, setAppSettings] = useState({});

  // --- Hooks --- //
  const history = useHistory();
  const location = useLocation();

  //Services
  const appSettingsService = useAppSettingsService();

  const handleLoginRedirect = async () => {
    Auth.handleRedirectCallback((error, response) => {
      if (error) {
        // console.error('HandleRedirectCallback', error);
      }
      if (response) {
        handleIsAuthenticated();
      }
    });
    Auth.loginRedirect(GRAPH_REQUESTS.LOGIN);
  };

  /**
   * Handles user login
   *  - will present pop up to use is not authenticated
   */
  const handleLogin = async () => {
    setCheckingLogin(true);
    /**
     * If Redirect flag is true
     *  - Initialize login redirect process and return
     */
    if (redirect) {
      setCheckingLogin(false);
      return Auth.loginRedirect(GRAPH_REQUESTS.LOGIN);
    }

    /**
     * Initialize user popup login process
     */
    const loginResponse = await Auth.loginPopup(GRAPH_REQUESTS.LOGIN).catch(
      (error) => {
        // this.setState({
        //   error: error.message
        // });
        setCheckingLogin(false);
        // console.error('Login failed');
      }
    );

    /**
     * If LoginResponse is truthy
     *  - Acquire and set user profile information
     *  - NOTE: see nested comments below for details
     */
    if (loginResponse) {
      // set account information
      setAccount(loginResponse);

      /**
       * Acquire microsoft graph token
       */
      const apiTokenResponse = await acquireToken({
        scopes: [process.env.REACT_APP_AD_API_ID],
      }).catch((error) => {
        // this.setState({
        //   error: error.message
        // });
        // console.error('Token not acquired');
      });

      /**
       * Checks if token response is truthy
       */
      if (apiTokenResponse) {
        setToken(apiTokenResponse.accessToken);
      }

      /**
       * Acquire microsoft graph token
       */
      const tokenResponse = await acquireToken(GRAPH_REQUESTS.LOGIN).catch(
        (error) => {
          // this.setState({
          //   error: error.message
          // });
          // console.error('Token not acquired');
        }
      );

      /**
       * Checks if token response is truthy
       */
      if (tokenResponse) {
        //setToken(tokenResponse.accessToken);
        const graphProfile = await fetchMsGraph(
          GRAPH_ENDPOINTS.ME,
          tokenResponse.accessToken
        ).catch(() => {
          // console.error('Unable to fetch graph profile.');
        });

        /**
         * Set user profile information
         *  - set authentication to true
         */
        if (graphProfile) {
          setUser(graphProfile);
          // console.debug('graphProfile', graphProfile);

          appSettingsService
            .getAppSettings(graphProfile.mail)
            .then((response) => {
              if (response.status === 200) {
                setAppSettings(response.data);
              }
              // set isAuthenticated
              setCheckingLogin(false);
              handleIsAuthenticated();
            });
        } else {
          handleNotAuthenticated();
          setCheckingLogin(false);
          redirectToError();
        }
      }
    }
  };

  /**
   * Handles user logout
   */
  const handleLogout = async () => {
    Auth.logout();
  };

  /**
   * Handle redirect to dashboard
   */
  const redirectToDashboard = () => history.push('/calendar');

  /**
   * Handles redirect to Authentication page
   */
  const redirectToLanding = () => history.push('/');

  /**
   * Handles redirect to Error page
   */
  const redirectToError = () => history.push('/error/authentication');

  /**
   * Handle setting isAuthentication to true
   */
  const handleIsAuthenticated = () => setIsAuthenticated(true);

  /**
   * Handle setting isAuthentication to false
   */
  const handleNotAuthenticated = () => setIsAuthenticated(false);

  const checkForSavedAuthentication = async () => {
    return new Promise((resolve, reject) => {
      if (!isAuthenticated) {
        acquireToken({ scopes: [process.env.REACT_APP_AD_API_ID] })
          .then((apiTokenResponse) => {
            if (apiTokenResponse) {
              setToken(apiTokenResponse.accessToken);
              acquireToken(GRAPH_REQUESTS.LOGIN)
                .then((tokenResponse) => {
                  if (tokenResponse) {
                    fetchMsGraph(GRAPH_ENDPOINTS.ME, tokenResponse.accessToken)
                      .then((graphProfile) => {
                        if (graphProfile) {
                          setUser(graphProfile);
                          appSettingsService
                            .getAppSettings(graphProfile.mail)
                            .then((response) => {
                              if (response.status === 200) {
                                setAppSettings(response.data);
                              }
                              handleIsAuthenticated();
                              resolve(true);
                            });
                        }
                      })
                      .catch(() => {
                        // console.error('Unable to fetch graph profile.');
                      });
                  }
                })
                .catch(() => {
                  // console.error('Token not acquired');
                });
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            resolve(false);
          });
      } else {
        resolve(true);
      }
    });
  };

  const getTokenForApi = async () => {
    return new Promise((resolve, reject) => {
      acquireToken({ scopes: [process.env.REACT_APP_AD_API_ID] })
        .then((apiTokenResponse) => {
          if (apiTokenResponse) {
            resolve(apiTokenResponse.accessToken);
          } else {
            resolve('');
          }
        })
        .catch((error) => {
          resolve('');
        });
    });
  };

  const updateAppSettings = (newSettings) => {
    setAppSettings({ ...appSettings, ...newSettings });
  };

  /**
   * --- Effect ---
   */
  useEffect(() => {
    /**
     * If user is not authenticated
     *  - if location pathname does not match: Redirect to '/'
     *  - Initialize login process
     */
    if (!isAuthenticated) {
      setCheckingLogin(true);
      checkForSavedAuthentication();
      setCheckingLogin(false);
    }
    // handleLogin();
    /**
     * If user is authenticated
     *  - If location pathname does match '/': Redirect to '/dashboard'
     */
    if (isAuthenticated) {
      if (
        matchPath(location.pathname, {
          path: '/',
          exact: true,
          strict: true,
        }) !== null ||
        matchPath(location.pathname, {
          path: '/logout',
          exact: true,
          strict: true,
        }) !== null
      ) {
        redirectToDashboard();
      }
    }
  }, [isAuthenticated]);

  return (
    <Provider
      value={{
        user,
        isAuthenticated,
        handleLogin,
        handleLogout,
        handleLoginRedirect,
        checkForSavedAuthentication,
        token,
        checkingLogin,
        getTokenForApi,
        appSettings,
        updateAppSettings,
      }}
    >
      {props.children}
    </Provider>
  );
};

/**
 * A public higher-order component to access the imperative API
 */
export const withAuth = (Component) => {
  return (props) => (
    <Consumer>{(auth) => <Component {...props} auth={auth} />}</Consumer>
  );
};

/**
 * React Hook to access the imperative API
 */
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
