import React, { Fragment } from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';

const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Español' }
};

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: 'none',
    backgroundColor: '#f1f2ef',
    backgroundImage: 'url(/images/background_title.jpg)',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    color: theme.palette.primary.main,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: '35px',
    },
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  // const [notifications] = useState([]);

  // const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  // const handleClose = () => {
  //   setHelpDialogOpen(false);
  // };
  // const handleOpen = () => {
  //   setHelpDialogOpen(true);
  // };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/branches">
          <img
            alt="Logo"
            src="/images/logos/nwcu-logo.png"
            className={classes.logo}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
          {Object.keys(lngs).map((lng) => (
              <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => {i18n.changeLanguage(lng); moment.locale(lng);
              }}>
                {lngs[lng].nativeName}
              </button>
            ))}
        {/* <Hidden mdDown> */}
        {/* <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={handleOpen}
        >
          <HelpIcon />
        </IconButton> */}
        {/* THIS WILL NEED TO GO SOMEWHERE ELSE
        The dialog should be its own component */}
        {/* <HelpDialog onClose={handleClose} open={helpDialogOpen} /> */}
        {/* </Hidden> */}
        {/* <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
