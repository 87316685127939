import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import * as yup from 'yup';
import { BaseClass } from 'utilities';
import { useAuth } from 'services/AuthService';

//Components
import { Subtitle } from 'components';

//Material UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Button,
  TextField as MuiTextField,
  MenuItem,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Formik
import { TextField } from 'formik-material-ui';
import { Formik, Form } from 'formik';

//Schema
import AddSlotSchema from './AddSlotSchema';

//Redux
import { useSelector, useDispatch } from 'react-redux';

const AddSlotModel = class extends BaseClass {
  constructor(props = {}) {
    super(props);
    this.quantity = props.quantity || '';
    this.date = props.date || '';
    this.startTime = props.startTime || '';
    this.duration = props.duration || '';
  }
};

// --- Styles --- //
const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid #dddddd',
    borderRadius: '15px',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
    },
    marginBottom: '15px',
  },
  note: {
    fontStyle: 'italic',
    fontWeight: 300,
    lineHeight: 1.3,
    letterSpacing: '0.1px',
  },
}));

const AddSlot = (props) => {
  const classes = useStyles();
  const { close, slotService, type, ...rest } = props;
  const { isAuthenticated, getTokenForApi } = useAuth();

  //State Hooks
  const [loading, setLoading] = useState(false);

  //Redux Hooks
  const selectedBranch = useSelector((state) => state.reducer.selectedBranch);
  const addEventData = useSelector((state) => state.reducer.addEventData);

  const initialValues =
    type === 'drag'
      ? new AddSlotModel({
          quantity: '',
          date: moment(addEventData.start).format('MM/DD/YYYY'),
          startTime: moment(addEventData.start).format('HH:mm'),
          duration: moment(addEventData.end).diff(
            addEventData.start,
            'minutes'
          ),
        }).toObject()
      : new AddSlotModel().toObject();

  const onSubmit = (values, resetForm) => {
    setLoading(true);
    getTokenForApi()
      .then((token) => {
        if (isAuthenticated && token !== '') {
          const startDate = moment(
            values.date + ' ' + values.startTime
          ).toDate();
          const newEvent = {
            branchId: selectedBranch.id,
            startDate: startDate,
            duration: values.duration,
            quantity: parseInt(values.quantity),
          };

          slotService.createNewSlot(newEvent, token).then((result) => {
            setLoading(false);
            props.close();
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div className={classes.box}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={AddSlotSchema}
        onSubmit={(values, { resetForm }) => {
          // console.log('Submitted Values', values);
          onSubmit(values);
        }}
      >
        <Form>
          <Fragment>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.note}>
                  Note: Adding this slot is only adding a single instance of an slot. If you want this slot to occur regularly, edit your branch's template.
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                {' '}
                <TextField
                  name="date"
                  label="Date"
                  variant="outlined"
                  helperText={
                    type === 'button' ? 'Example Format: 01/01/2020' : ''
                  }
                  fullWidth
                  disabled={type === 'drag' ? true : false}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="startTime"
                  label="Start Time"
                  helperText={
                    type === 'button' ? 'Example Format: 11:30 AM' : ''
                  }
                  type="time"
                  inputProps={{
                    min: '06:00',
                    max: '21:00',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  fullWidth
                  disabled={type === 'drag' ? true : false}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="duration"
                  label="Duration in Minutes"
                  variant="outlined"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="quantity"
                  label="Number of Appointments"
                  variant="outlined"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" variant="contained" type="submit">
                  Add Slot to {selectedBranch.name}'s Calendar
                </Button>
                {loading && (
                  <CircularProgress
                    style={{ marginLeft: '20px' }}
                    color="secondary"
                  />
                )}
              </Grid>
            </Grid>
          </Fragment>
        </Form>
      </Formik>
    </div>
  );
};

export default AddSlot;
