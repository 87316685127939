import * as types from './Types';

export const setSelectedEvent = (data) => {
  return {
    type: types.SET_SELECTED_EVENT,
    data: data,
  };
};

export const setAllBranches = (data) => {
  return {
    type: types.SET_ALL_BRANCHES,
    data: data,
  };
};

export const setAllPurposes = (data) => {
  return {
    type: types.SET_ALL_PURPOSES,
    data: data,
  };
};

export const setSelectedBranch = (data) => {
  return {
    type: types.SET_SELECTED_BRANCH,
    data: data,
  };
};

export const setAddEventData = (data) => {
  return {
    type: types.SET_ADD_EVENT_DATA,
    data: data,
  };
};

export const setCurrentView = (data) => {
  return {
    type: types.SET_CURRENT_VIEW,
    data: data,
  };
};

export const setMode = (data) => {
  return {
    type: types.SET_MODE,
    data: data,
  };
};

export const setRescheduleInfo = (data) => {
  return {
    type: types.SET_RESCHEDULE_INFO,
    data: data,
  };
};

export const setTemplateSlots = (data) => {
  return {
    type: types.SET_TEMPLATE_SLOTS,
    data: data,
  };
};

export const setLoggedIn = (data) => {
  return {
    type: types.SET_LOGGED_IN,
    data: data,
  };
};
