// --- Class Imports --- //
import Config from './RouteConfig';

// --- Layout Imports --- //
import { default as MainLayout } from '../layouts/Main';

// --- Icon Imports --- //
import { default as ApplicationsIcon } from '@material-ui/icons/InsertDriveFile';

// --- View Imports --- //
import { default as MainCalendarView } from 'views/MainCalendarView';
import { default as CustomerMainCalendarView } from 'views/CustomerMainCalendarView';
import { default as EditTemplateView } from 'views/EditTemplateView';
import { default as BranchView } from 'views/BranchView';
import { default as EmployeeBranchView } from 'views/EmployeeBranchView';
import { default as CancelView } from 'views/CancelView';
import { default as LandingView } from 'views/Landing';
import { default as LogOutView } from 'views/LogOut';

/**
 * Dashboard link and route config
 */
// const DashboardConfig = new Config({
//   component: DashboardView,
//   layout: MainLayout,
//   paths: ['/dashboard'],
//   title: 'Dashboard',
//   icon: DashboardIcon,
//   href: '/dashboard',
// });

// TEMPLATE VIEW
//const EditTemplateConfig = new Config({
//  component: EditTemplateView,
//  layout: MainLayout,
//  paths: ['/template'],
//  title: 'Template',
//  icon: '',
//  href: '/template',
//  isProtected: true,
//});

//Customer Branch View
const BranchViewConfig = new Config({
  component: BranchView,
  layout: MainLayout,
  paths: ['/branches'],
  title: 'Branches',
  icon: ApplicationsIcon,
  href: '/branches',
});

//Employee Branch View
const EmployeeBranchViewConfig = new Config({
  component: EmployeeBranchView,
  layout: MainLayout,
  paths: ['/select-a-branch'],
  title: 'Select a Branch',
  icon: ApplicationsIcon,
  href: '/select-a-branch',
});

//Main Calendar View
//const MainCalendarConfig = new Config({
//  component: MainCalendarView,
//  layout: MainLayout,
//  paths: ['/calendar'],
//  title: 'Calendar',
//  icon: ApplicationsIcon,
//  href: '/calendar',
//  isProtected: true,
//});

//Customer Main Calendar View
const CustomerMainCalendarConfig = new Config({
  component: CustomerMainCalendarView,
  layout: MainLayout,
  paths: ['/calendar', '/calendar/:id'],
  title: 'Calendar',
  icon: ApplicationsIcon,
  href: '/calendar',
});

//Cancel View
const CancelConfig = new Config({
  component: CancelView,
  layout: MainLayout,
  paths: ['/reservation/:id'],
  title: 'Cancel',
  icon: ApplicationsIcon,
  href: '/reservation',
});

/**
 * Landing link and route config
 */
//const LandingConfig = new Config({
//  component: LandingView,
//  layout: MainLayout,
//  paths: ['/', '/landing'],
//  title: 'Landing',
//  href: '/',
//});

/**
 * Landing link and route config
 */
//const LogOutConfig = new Config({
//  component: LogOutView,
//  layout: MainLayout,
//  paths: ['/logout'],
//  title: 'Landing',
//  href: '/logout',
//});

/**
 * Defines public routes
 */
export const publicRoutes = [
  CustomerMainCalendarConfig,
  BranchViewConfig,
  CancelConfig,
];

/**
 * Defines protected routes
 */
export const protectedRoutes = [];

export default [...publicRoutes, ...protectedRoutes];
