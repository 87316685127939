import React from 'react';
import logo from './logo.svg';
import './App.css';
import MyCalendar from './MyCalendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './assets/scss/index.scss';

// --- Router Imports --- //
import { Router } from 'react-router-dom';
import Routes from './routes';
import { createBrowserHistory } from 'history';
import { ScrollToTop } from 'routes/components';

// --- Theme Imports --- //
import { ThemeProvider } from '@material-ui/styles';
import theme from './themes';

// --- Store Imports --- //
import { Provider } from 'react-redux';
import store from 'store';

//Services
import { useBranchService } from 'services/BranchService';
import { usePurposeService } from 'services/PurposeService';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store';

import moment from 'moment';
import 'moment/locale/es';
import { useTranslation, Trans } from 'react-i18next';

const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Español' }
};

// import { ScrollToTop } from 'routes/components';
const browserHistory = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <AppContents />
    </Provider>
  );
}

const AppContents = () => {

  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);

  //Hooks
  const dispatch = useDispatch();
  const branchService = useBranchService();
  const purposeService = usePurposeService();

  //Get branches from API
  branchService.getBranches(moment.locale()).then((response) => {
    dispatch(actions.setAllBranches(response.data));
  });

  //Get purposes from API
  purposeService.getPurposes(moment.locale()).then((response) => {
    dispatch(actions.setAllPurposes(response.data));
  });

  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <ScrollToTop />
        <Routes />
      </Router>
    </ThemeProvider>
  );
};

export default App;
